import React, { useRef, useState } from 'react'
import {
	Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import DialogTitleHeaderComponent from './dialog-title-header.component'
import Draggable from 'react-draggable'

interface IProps {
	open: boolean
	handleConfirmClose: any
	confirmStatus: any
	content: string
	classes: ClassNameMap
}

const ConfirmationDialogComponent: React.FC<IProps> = ({ open, handleConfirmClose, confirmStatus, content, classes }) => {
	const [isConfirm, setIsConfirm] = useState(false)
	const confirmRef = useRef(null) as any

	const hadleConfirm = () => {
		setIsConfirm(true)
		confirmStatus(true)
	}

	return (
		<Draggable ref={confirmRef} defaultPosition={{ x: 80, y: 20 }} handle='.confirmation-dialog'>
			<Dialog
				id={`confirmation-id`}
				fullWidth={true}
				maxWidth="sm"
				open={open}
				onClose={handleConfirmClose}
				hideBackdrop
				disableScrollLock
				sx={{
					position: "absolute",
					left: '10rem',
					top: '8rem'
				}}
				onKeyUp={(e) => {
					if (e.key === 'Enter' && !isConfirm) confirmStatus(true)
				}}
			>
				<DialogTitle className={classes.questionHeaderSection} classes={{ root: 'confirmation-dialog' }}>
					<DialogTitleHeaderComponent
						title={'Confirmation'}
						handleDialogClose={handleConfirmClose}
					/>
				</DialogTitle>
				{isConfirm && <LinearProgress />}
				<DialogContent sx={{ padding: '1rem !important' }}>
					<Typography variant="subtitle2">{content}</Typography>
				</DialogContent>
				<DialogActions className={classes.cardLabInfo}>
					<Button
						variant="outlined"
						disabled={isConfirm}
						size="small"
						onClick={() => hadleConfirm()}
					>
						{`YES`}
					</Button>
					<Button variant="outlined" size="small" onClick={() => handleConfirmClose()}                >
						{`NO`}
					</Button>
				</DialogActions>
			</Dialog>
		</Draggable>
	)
}

export default withStyles(stylesheet)(ConfirmationDialogComponent)