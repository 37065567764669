import { environment } from '../environments/environment'
import axios from 'axios'

const apiEndpoint = environment.webservices.endpoint

export class PatientService {
  // constructor() { }

  getPatients(limit: number = 20, page: number = 1) {
    const endpoint = `${apiEndpoint}/patients?limit=${limit}&page=${page}`
    return axios.get(endpoint)
  }
  getKBVersion() {
    return axios.get(`${apiEndpoint}/check-kb-version`)
  }
  getPatientsWithFilters(filters: any) {
    const endpoint = `${apiEndpoint}/patients?limit=${filters.limit}&page=${filters.page}&programs=${filters.programs}&hospitals=${filters?.hospitals}&search=${filters.search}&state=${filters.state}&stage=${filters.stage}&status=${filters.status}&sort=${filters.sort}`
    return axios.get(endpoint)
  }

  getFilterPatients(filterParam: any, limit: number = 20, page: number = 1) {
    let endpoint = ''
    if (filterParam === 'all') {
      endpoint = `${apiEndpoint}/patients?limit=${limit}&page=${page}`
    } else {
      endpoint = `${apiEndpoint}/patients?stage=${filterParam}&limit=${limit}&page=${page}`
    }
    return axios.get(endpoint)
  }

  getPatientInfo(patientId: string, selectedPrograms: string[] = []) {
    let endpoint = ''
    if (selectedPrograms.length) {
      endpoint = `${apiEndpoint}/patients/${patientId}?${selectedPrograms
        .map((e, i) => (i === 0 ? `program=${e}` : `&program=${e}`))
        .join('')}`
    } else {
      endpoint = `${apiEndpoint}/patients/${patientId}`
    }
    return axios.get(endpoint)
  }

  syncEHRPatients() {
    const endpoint = `${apiEndpoint}/fhir/patients/sync`
    return axios.get(endpoint)
  }
  syncEPICPatients(mrn: any, prg_list: any) {
    const endpoint = `${apiEndpoint}/fhir/patients/${mrn}/sync?programs_name=${prg_list}`
    return axios.get(endpoint)
  }
  syncELATIONPatients(mrn: any, prg_list: any) {
    const endpoint = `${apiEndpoint}/elation/patients/${mrn}/sync?programs_name=${prg_list}`
    return axios.get(endpoint)
  }
  syncEHRPatientInfo(patientRefId: string) {
    const endpoint = `${apiEndpoint}/fhir/patients/${patientRefId}/sync`
    return axios.get(endpoint)
  }
  createCase(patientId: string, programName: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/salesforce/cases/${programName}`
    return axios.get(endpoint, {})
  }

  getTreatmentPlan(patientId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/treatment-plan`
    return axios.get(endpoint, {})
  }
  getPrescriptionUnit(searchPattern: string) {
    const endpoint = `${apiEndpoint}/prescription-units?name=${searchPattern}`
    return axios.get(endpoint, {})
  }

  putProgramConditionsQuestionnaire(
    patientId: string,
    entityRefId: string,
    payload: any
  ) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/condition-evaluation/${entityRefId}`
    return axios.put(endpoint, payload)
  }

  PostConfirmations(payload: any) {
    const endpoint = `${apiEndpoint}/confirmations`
    return axios.post(endpoint, payload)
  }
  putProgramMeasurements(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/measurements/program_targets`
    return axios.put(endpoint, payload)
  }
  getMeasurementRationale(program: any, name: any) {
    const endpoint = `${apiEndpoint}/get_rationale_dependencies/${name}/${program}`
    return axios.get(endpoint)
  }
  getPSCRationale(program: any, psc: any) {
    const endpoint = `${apiEndpoint}/get_psc_rationale_dependencies/${psc}/${program}`
    return axios.get(endpoint)
  }
  getCalculatorsValue(patientId: string, calId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/calculators/${calId}`
    return axios.put(endpoint, {})
  }
  postOtherMedication(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/medications`
    return axios.post(endpoint, payload)
  }
  getUIResources() {
    const endpoint = `${apiEndpoint}/resources/picklist-values`
    return axios.get(endpoint, {})
  }

  getMetaData() {
    const endpoint = `${apiEndpoint}/resources/picklist-values`
    return axios.get(endpoint)
  }
  postMedicationReaction(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/med-intolerances`
    return axios.post(endpoint, payload)
  }
  getMedicationReaction() {
    const endpoint = `${apiEndpoint}/medication-reactions`
    return axios.get(endpoint, {})
  }
  putMedicationReaction(patientId: string, entityId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/med-intolerances/${entityId}`
    return axios.put(endpoint, payload)
  }

  getOtherConditions(searchPattern: string) {
    const endpoint = `${apiEndpoint}/indications?name=${searchPattern}`
    return axios.get(endpoint, {})
  }

  postOtherConditions(payload: any, patientId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/conditions`
    return axios.post(endpoint, payload)
  }

  deleteOtherMedications(patientId: string, id: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/medications/${id}`
    return axios.delete(endpoint, {})
  }
  deleteMedIntolerances(patientId: string, id: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/med-intolerances/${id}`
    return axios.delete(endpoint, {})
  }
  deleteOtherConditions(patientId: string, id: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/conditions/${id}`
    return axios.delete(endpoint, {})
  }
  putOtherMedication(patientId: string, entityId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/medications/${entityId}`
    return axios.put(endpoint, payload)
  }
  editOtherConditions(payload: any, patientId: string, quesId: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/conditions/${quesId}`
    return axios.put(endpoint, payload)
  }
  getUpdatedStage(patientId: string, _enrollments: number[]) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/enrollments/stages`
    return axios.put(endpoint, { enrollments: _enrollments })
  }
  getClinicalScore(patientId: string, enrollId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/enrollments/${enrollId}`
    return axios.get(endpoint, {})
  }
  postProgramChecks(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/measurements/program_checks`
    return axios.put(endpoint, payload)
  }
  postOthers(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/measurements/others`
    return axios.put(endpoint, payload)
  }
  getRxNorms(
    name: string,
    propagation: string,
    aa_formulation: boolean,
    medication_class: boolean,
    ingredient: boolean
  ) {
    const endpoint = `${apiEndpoint}/rxnorms?name=${name}&propagation=${propagation}&medication=${aa_formulation}&class=${medication_class}&ingredient=${ingredient}`
    return axios.get(endpoint, {})
  }
  postExpressMode(payload: any) {
    const endpoint = `${apiEndpoint}/confirmations/express-mode`
    return axios.post(endpoint, payload)
  }
  deepLink(patientId: string, enrollId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/enrollments/${enrollId}/salesforce-deeplink`
    return axios.get(endpoint, {})
  }
  captureBaseline(enrollId: string) {
    const endpoint = `${apiEndpoint}/patients/${enrollId}/baseline-data`
    return axios.post(endpoint, {})
  }
  postReferral(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/referrals`
    return axios.put(endpoint, payload)
  }
  getReferral(patientId: string, entityRef: string, refId: number) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/referrals/${refId}`
    return axios.get(endpoint, {})
  }
  getOverrideNote(patientId: string, entityRef: string, eId: number) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/latest-override-notes?entity_ref=${entityRef}&entity_id=${eId}`
    return axios.get(endpoint, {})
  }
  removeOverride(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/remove-override`
    return axios.put(endpoint, payload)
  }
  postOverrideValue(payload: any) {
    const endpoint = `${apiEndpoint}/manual-override`
    return axios.put(endpoint, payload)
  }
  getFilterCounts(program: string, hospitals: string) {
    const endpoint = `${apiEndpoint}/filter-data-counts?programs=${program}&hospitals=${hospitals}`
    return axios.get(endpoint, {})
  }
  getIcdCode(searchPattern: string) {
    const endpoint = `${apiEndpoint}/icds?search=${searchPattern}`
    return axios.get(endpoint, {})
  }
  getPscDetails(patientId: string, entityRefId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/condition-evaluation/${entityRefId}`
    return axios.get(endpoint, {})
  }
  tokenVerification() {
    const endpoint = `${apiEndpoint}/auth/token-verification`
    return axios.post(endpoint)
  }
  putPatientActions(
    patientId: string,
    id: string,
    program_name: string,
    action: string,
    sub_action: string,
    _care_plan_id: any
  ) {
    let endpoint = `${apiEndpoint}/patients/${patientId}/${program_name}/med-actions?action=${encodeURI(
      action
    )}`
    if (sub_action !== '') endpoint += `&sub_action=${sub_action}`
    return axios.put(endpoint, {
      medication_id: id,
      care_plan_id: _care_plan_id,
    })
  }
  getMedClasses(carePlanId: number, limit: number = 20, page: number = 1) {
    const endpoint = `${apiEndpoint}/med-classes?care-planId=${carePlanId}&limit=${limit}&page=${page}`
    return axios.get(endpoint)
  }
  getMedications(classId: any, limit: number = 20, page: number = 1) {
    const endpoint = `${apiEndpoint}/aa_formulations/${classId}`
    return axios.get(endpoint)
  }
  postProviderMedications(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/medications?group=provider_recommended`
    return axios.post(endpoint, payload)
  }
  getMedFrequencyByUnit(data: any) {
    const endpoint = `${apiEndpoint}/resources/frequency_list/${data}`
    return axios.get(endpoint)
  }
  getMeasurementReport(patientId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/measurement-report-data`
    return axios.get(endpoint)
  }
  getSchedules(aa_id: any) {
    const endpoint = `${apiEndpoint}/aa_formulation/schedules/${aa_id}`
    return axios.get(endpoint)
  }
  getAAFormContextualHelp(id: any) {
    const endpoint = `${apiEndpoint}/disease-education/aa_formulations/${id}`
    return axios.get(endpoint)
  }
  getContextualHelp(category: string, id: any) {
    const endpoint = `${apiEndpoint}/disease-education/${category}/${id}`
    return axios.get(endpoint)
  }
  getMedChoicesData(patientId: string, medId: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/med-choices/${medId}`
    return axios.get(endpoint)
  }
  getMedRestrictions(patientId: string, prg: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/check_exclusions/${prg}`
    return axios.get(endpoint)
  }
  getRestrictedMed(
    patientId: string,
    recordId: string,
    medType: string,
    type: any
  ) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/restricted-aa-forms?id=${recordId}&type=${medType}&propagation=${type}`
    return axios.get(endpoint)
  }
  getMedMoreDetails(patientId: string, recordId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/medications/${recordId}/fhir-json`
    return axios.get(endpoint)
  }
  deleteMedication(patientId: string, recordId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/delete_medication/${recordId}`
    return axios.delete(endpoint)
  }
  getImportedMedMoreDetails(patientId: string, recordId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/imported-medications/${recordId}/fhir-json`
    return axios.get(endpoint)
  }
  postNewPatient(payload: any) {
    const endpoint = `${apiEndpoint}/ccd/uploads`
    return axios.post(endpoint, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  changeProgramStatus(patientId: string, program_name: string, status: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/${program_name}/${status}`
    return axios.put(endpoint)
  }
  runToxicities(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/toxicities`
    return axios.post(endpoint, payload)
  }
  changeProgramStage(patientId: string, program_name: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/${program_name}/change-stage`
    return axios.put(endpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
  getPatientProfileDetails(patientId: string) {
    const endpoint = `${apiEndpoint}/patient-profile/${patientId}`
    return axios.get(endpoint)
  }
  putPatientProfileDetails(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patient-profile/${patientId}`
    return axios.put(endpoint, payload)
  }
  getMedHistory(medId: string) {
    const endpoint = `${apiEndpoint}/medications/${medId}/history`
    return axios.get(endpoint)
  }
  getMeasurementHistory(patientId: string, mesureId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/measurements/${mesureId}/history`
    return axios.get(endpoint)
  }
  getTargetHistory(patientId: string, program: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/target-history?program=${program}`
    return axios.get(endpoint)
  }
  resetPatient(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/reset`
    return axios.put(endpoint, payload)
  }
  regenerateCarePlan(patientId: string, carePlanId: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/care_plans/${carePlanId}/regenerate`
    return axios.get(endpoint)
  }

  resetCarePlan(patientId: string, carePlanId: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/care_plans/${carePlanId}/reset`
    return axios.get(endpoint)
  }

  deviceActivation(patientId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/withings/device-activation`
    return axios.post(endpoint)
  }
  syncDeviceData(patientId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/withings/device-sync`
    return axios.post(endpoint)
  }
  syncMeasurementData(patientId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/device-data-sync/weight`
    return axios.post(endpoint)
  }
  journeyByProgram(patientId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/journey`
    return axios.get(endpoint)
  }
  measurementsUpdate(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/measurements/update`
    return axios.put(endpoint, payload)
  }
  deleteTargetHistory(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/target-histories/removal`
    return axios.post(endpoint, payload)
  }
  deleteMeasurementsHistory(patientId: string, mId: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/measurements/${mId}`
    return axios.delete(endpoint)
  }
  extractHospitalRXReport(hospital_name: any) {
    const endpoint = `${apiEndpoint}/hospitals/${hospital_name}/exports/rx-report`
    return axios.get(endpoint)
  }
  extractHospitalBPReport(hospital_name: any) {
    const endpoint = `${apiEndpoint}/hospitals/${hospital_name}/exports/bp-report`
    return axios.get(endpoint)
  }
  extractHospitalInteractionReport(hospital_name: any) {
    const endpoint = `${apiEndpoint}/hospitals/${hospital_name}/exports/interaction-report`
    return axios.get(endpoint)
  }
  extractHospitalLabReport(hospital_name: any) {
    const endpoint = `${apiEndpoint}/hospitals/${hospital_name}/exports/lab-report`
    return axios.get(endpoint)
  }
  fhirPatientAdd(mrn: string, programs: string) {
    const endpoint = `${apiEndpoint}/fhir/patients/${mrn}/sync?programs_name=${programs}`
    return axios.get(endpoint)
  }
  elationPatientAdd(mrn: string, programs: string) {
    // http://localhost:5000/elation/patients/141344889503745/sync
    const endpoint = `${apiEndpoint}/elation/patients/${mrn}/sync?programs_name=${programs}`
    return axios.get(endpoint)
  }
  addManualPatient(payload: any) {
    const endpoint = `${apiEndpoint}/patients`
    return axios.post(endpoint, payload)
  }
  exportPatient(patientId: string) {
    return axios({
      url: `${apiEndpoint}/patients/${patientId}/export`,
      method: 'GET',
      responseType: 'blob', // to download file
    })
  }
  elationimportStatus() {
    const endpoint = `${apiEndpoint}/elation/patients/check-import-status`
    return axios.get(endpoint)
  }
  getElationImport() {
    const endpoint = `${apiEndpoint}/elation/patients/`
    return axios.post(endpoint)
  }
  extractTreatmentReport(hospital_name: any) {
    const endpoint = `${apiEndpoint}/hospitals/${hospital_name}/exports/treatment-goal-report`
    return axios.get(endpoint)
  }
  generateExports(hospital_name: any) {
    const endpoint = `${apiEndpoint}/hospitals/${hospital_name}/generate-exports`
    return axios.post(endpoint)
  }
  downloadeExportsReport() {
    return axios({
      url: `${apiEndpoint}/elation/patients/import-status`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  downloadeElationReport() {
    return axios({
      url: `${apiEndpoint}/elation/patients/import-status`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  generateNote(patientId: string) {
    return axios.get(
      `${apiEndpoint}/elation/patients/${patientId}/generate_note`
    )
  }
  closeoutNote(patientId: string, payload: any) {
    return axios.post(
      `${apiEndpoint}/elation/patients/${patientId}/generate_closeout_note`,
      payload
    )
  }
  submitNote(patientId: string, payload: any) {
    return axios.post(
      `${apiEndpoint}/elation/patients/${patientId}/submit_note`,
      payload
    )
  }
  importPatient(payload: any) {
    const endpoint = `${apiEndpoint}/patients/import`
    return axios.post(endpoint, payload)
  }
  runProgramEvaluation(id: string) {
    const endpoint = `${apiEndpoint}/program_evaluation/${id}`
    return axios.get(endpoint, {})
  }
  getCalculatedPSC(patientId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/get_program_specific_conditions`
    return axios.get(endpoint, {})
  }
  recalculateICD10(patientId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/icd-codes`
    return axios.get(endpoint, {})
  }
  syncDeviceMeasurements() {
    const endpoint = `${apiEndpoint}/patients/withings/device-sync`
    return axios.post(endpoint, {})
  }
  getMedicationReactionBySearch(name: string) {
    const endpoint = `${apiEndpoint}/symptoms?name=${name}`
    return axios.get(endpoint, {})
  }
  getMedEvalRejectDDL(
    program_name: string,
    eval_status: string,
    med_name: string
  ) {
    const endpoint = `${apiEndpoint}/provider_actions/${program_name}/${eval_status}/${med_name}`
    return axios.get(endpoint)
  }
  getMedicationReactionBySearchDiagnosis(name: string) {
    const endpoint = `${apiEndpoint}/diagnoses?name=${name}`
    return axios.get(endpoint, {})
  }
  getQuestionnaires(limit: number = 25, page: number = 1) {
    const endpoint = `${apiEndpoint}/questionnaires`
    return axios.get(endpoint)
  }
  getQuestionnaireDataset(questionnaireId: string, patientId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/questionnaires/${questionnaireId}`
    const resultSet = axios
      .get(endpoint)
      .then((response: any) => {
        return response !== undefined &&
          response?.data &&
          response?.data?.questionnaire
          ? response?.data?.questionnaire
          : {}
      })
      .catch((error: any) => {
        console.error(error)
        return {}
      })
    return resultSet
  }
  updateQuestionnaires(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/calculator-questionnaire/answers`
    return axios.put(endpoint, payload)
  }
  postNotes(payload: any) {
    const endpoint = `${apiEndpoint}/notes`
    return axios.post(endpoint, payload)
  }
  getNotes(page: string, limit: string, entityRef: string, entityId: string) {
    const endpoint = `${apiEndpoint}/notes?page=${page}&limit=${limit}&entity_ref=${entityRef}&entity_id=${entityId}`
    return axios.get(endpoint)
  }
  triggerFHIRPatientSync(payload: any) {
    const endpoint = `${apiEndpoint}/epic-fhir-oauth/patients/sync`
    return axios.post(endpoint, payload)
  }
  getMedicationSchedule(patientId: any, params: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}/best_possible_schedules/${params}`
    return axios.get(endpoint)
  }
  getMonitorList() {
    const endpoint = `${apiEndpoint}/monitor-names`
    return axios.get(endpoint)
  }
  createMonitor(enrollment_id: any, monitor_name: any) {
    const endpoint = `${apiEndpoint}/monitors/create/${enrollment_id}/ref/${monitor_name}`
    return axios.post(endpoint)
  }
  getLifestylesList() {
    const endpoint = `${apiEndpoint}/lifestyle-names`
    return axios.get(endpoint)
  }
  createLifestyle(enrollment_id: any, lifestyle_name: any) {
    const endpoint = `${apiEndpoint}/lifestyles/create/${enrollment_id}/ref/${lifestyle_name}`
    return axios.post(endpoint)
  }
  changeProgramStageByName(stage_name: string, enrollment_id: string) {
    const endpoint = `${apiEndpoint}/patient/stage_transition/${stage_name}/enrollment/${enrollment_id}`
    return axios.get(endpoint)
  }
  getMonitorDetails(pId: string, monitor_id: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/monitors/${monitor_id}`
    return axios.get(endpoint)
  }
  getLifestyleDetails(pId: string, lifestyle_id: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/lifestyles/${lifestyle_id}`
    return axios.get(endpoint)
  }
  stopMonitorDetails(pId: string, monitor_id: any, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/monitors/${monitor_id}`
    return axios.put(endpoint, payload)
  }
  stopLifestyleDetails(pId: string, lifestyle_id: any, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/lifestyles/${lifestyle_id}`
    return axios.put(endpoint, payload)
  }
  deleteMonitorDetails(pId: string, monitor_id: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/monitors/${monitor_id}`
    return axios.delete(endpoint, {})
  }
  deleteLifestyleDetails(pId: string, lifestyle_id: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/lifestyles/${lifestyle_id}`
    return axios.delete(endpoint, {})
  }
  getMonitorvaluation(id: string) {
    const endpoint = `${apiEndpoint}/monitor_evaluation/${id}`
    return axios.get(endpoint, {})
  }
  createEnrollment(pId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/enrollment/create`
    return axios.post(endpoint, payload)
  }
  getProblemsList() {
    const endpoint = `${apiEndpoint}/problem-names`
    return axios.get(endpoint)
  }
  createProblem(pId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/problems`
    return axios.post(endpoint, payload)
  }
  deleteProblemDetails(pId: string, problemId: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/problems/${problemId}`
    return axios.delete(endpoint, {})
  }
  updateProblem(pId: string, prbId: any, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/problems/${prbId}/add-program`
    return axios.post(endpoint, payload)
  }
  removeEnrollment(pId: string, prgId: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/enrollments/${prgId}`
    return axios.delete(endpoint, {})
  }
  updateLifestyle(pId: string, lifestyle_id: any, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/lifestyles/${lifestyle_id}`
    return axios.post(endpoint, payload)
  }
  extendMonitor(pId: string, mId: any, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/monitors/${mId}/extend`
    return axios.put(endpoint, payload)
  }
  extendLifestyle(pId: string, lifestyle_id: any, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/lifestyles/${lifestyle_id}/extend`
    return axios.put(endpoint, payload)
  }
  getMedEvidence(pId: string, program: any, med_id: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/programs/${program}/${med_id}`
    return axios.get(endpoint, {})
  }
  getMedReconcilation(id: any, code: any) {
    const endpoint = `${apiEndpoint}/patients/${id}/reconciliate-medications`
    return axios.get(endpoint, {})
  }
  confirmMedReconcilation(pId: string, payload: any) {
    const endpoint = `${apiEndpoint}/patients/${pId}/reconciliate-medications`
    return axios.put(endpoint, payload)
  }
  deletePatient(patientId: string) {
    const endpoint = `${apiEndpoint}/patients/${patientId}`
    return axios.delete(endpoint, {})
  }

  addLabReport(patientId: string, payload: any) {
    const endpoint = `${apiEndpoint}/elation/patients/${patientId}/import_lab_report_pdf`
    return axios.post(endpoint, payload)
  }
}
