import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ToolbarComponent from './toolbar.component';
import DrawerComponent from './drawer.component';
import { withStyles, ClassNameMap } from '@mui/styles'
import stylesheet from '../patients/detail/detail-container.stylesheet'
import { environment } from '../../environments/environment';
import { setLoggedInUser } from '../../redux/common-data-slice';
import { useAppThunkDispatch } from '../../redux/store';

interface IProps extends RouteComponentProps {
  history: any;
  location: any;
  match: any;
  classes: ClassNameMap;
}

const NavbarComponent: React.FC<IProps> = ({ classes }) => {
  const [left, setLeft] = useState(false);
  const dispatch = useAppThunkDispatch();

  useEffect(() => {
    //console.log(authState, oktaAuth)
    if (!environment.okta.isEnabled) {

      const userInfo = {
        'email': "sourav_ghosh@atmanhealth.com",
        'email_verified': true,
        'family_name': "Provider",
        'given_name': "Dev",
        'locale': "en_US",
        'name': "Dev Provider",
        // preferred_username:"sourav_ghosh@atmanhealth.com"
        //sub  : "00uezrds6mCW2sbpf5d7"
        //updated_at :1713879892
        // zoneinfo:"America/Los_Angeles"
        //  
      }
      dispatch(setLoggedInUser({ ...userInfo, roles: ['PROVIDER'] }))
    }
  }, [])

  const toggleDrawer = (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setLeft(false);
  };

  const openDrawer = () => setLeft(true);

  return (
    <div className={classes.navbar}>
      <ToolbarComponent
        openDrawerHandler={openDrawer}
        history={''}
        location={window.location}
        match={''}
      />
      <DrawerComponent
        left={left}
        toggleDrawerHandler={toggleDrawer}
        history={''}
        location={''}
        match={''}
      />
    </div>
  );
};

export default withStyles(stylesheet)(NavbarComponent);
