import {
  Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer,
  TableRow, Typography, Card, CircularProgress, TableHead,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import stylesheet from '../detail-container.stylesheet'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import DeleteIcon from '@mui/icons-material/Delete'
import SyncIcon from '@mui/icons-material/Sync'
import EditIcon from '@mui/icons-material/Edit'
import { PatientService } from '../../../../services/patient.service'
import { checkCondition } from '../common/condition-check'
import moment from 'moment'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import MenuIcon from '@mui/icons-material/Menu'
import ChartComponent from '../common/chart-component'
import EmptyCardComponent from '../common/empty-card.component'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'
import { RootState } from '../../../../redux/store'
import { useSelector } from 'react-redux'

interface IProps {
  input: any
  name: string
  outer: any
  patientId: string
  classes: ClassNameMap
}

const HistoryPDIComponent: React.FC<IProps> = ({
  input,
  name,
  outer,
  patientId,
  classes,
}) => {
  const [data, setData] = useState([] as any)
  const [loading, setLoading] = useState(false)
  const [chart, setChart] = useState(false)
  const patientService = new PatientService()
  const [sortList, setSortList] = useState({ sortBy: 'none', date: 'none', name: 'none' } as any)
  const [historyData, setHistoryData] = useState([] as any)
  const { updatePatientData } = useUpdateDataAction(patientId)
  const program_enrollments = useSelector((state: RootState) => state.patientDetail.patientDetail?.program_enrollments || [])

  useEffect(() => {
    setLoading(true)
    patientService
      .getMeasurementHistory(patientId, input.id)
      .then((res: any) => {
        setData(res.data.history)
        setHistoryData(sortData(res.data.history, 'table'))
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      })
  }, [])

  useEffect(() => {
    if (sortList?.sortBy === 'date') {
      setHistoryData((sortList?.date === 'desc'
        ? [...historyData]?.sort((a: any, b: any) => a.date < b.date ? 1 : -1)
        : [...historyData]?.sort((a: any, b: any) => a.date > b.date ? 1 : -1)
      ))
    }
  }, [sortList])

  const onCloseClick = (mode: string) => {
    outer(input.id, mode)
  }
  const onChartClick = () => {
    setChart(!chart)
  }

  const sortData = (d: any, type: string) => {
    if (type === 'chart') {
      return d.sort((a: any, b: any) => a.date_recorded - b.date_recorded)
    } else if (type === 'table') {
      return d.sort((a: any, b: any) => b.date_recorded - a.date_recorded)
    }
  }

  const temp = [...data]
  const chartData = sortData(temp, 'chart')
  const labels = chartData.map((d: any) => moment.unix(d.date_recorded).format('DD MMM YY'))

  const getSortIcon = (d: any) => {
    if (d === 'desc') {
      return <ArrowDropDownIcon className={classes.programTargetEditIcon} />
    } else if (d === 'asc') {
      return <ArrowDropUpIcon className={classes.programTargetEditIcon} />
    } else {
      return <UnfoldMoreIcon className={classes.programTargetEditIcon} />
    }
  }

  const sortHistroyByType = (type: string) => {
    sortList[type] === 'asc'
      ? setSortList({ ...sortList, sortBy: type, [type]: 'desc' })
      : setSortList({ ...sortList, sortBy: type, [type]: 'asc' })
  }

  const getTag = (d: any) => (d?.tag_detail)
    ? <small>{` (${d?.tag_detail?.tag_display_name || d?.tag_detail?.tag_name})`}</small>
    : ''

  const syncDeviceData = async () => {
    setLoading(true)
    patientService.syncMeasurementData(patientId).then((res: any) => {
      setLoading(false)
      const mData = res?.data || {}
      if (Object.keys(mData)?.length > 0) {
        if (mData?.message === 'No data imported')
          toast.warning('No data imported!', Helper.bottom_center())
        else if (mData?.id && mData?.message === 'success') {
          updatePatientData({ isMeasurement: true })
        }
      }
    }).catch((error) => {
      setLoading(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const getValueRange = (val: number, range = '') => {
    return (val && range === 'max')
      ? val + ((val * 5) / 100)
      : (val && range === 'min')
        ? val - ((val * 5) / 100)
        : val
  }

  return <Grid className={classes.customCard}>
    <Card variant="outlined">
      {!chart &&
        <Box className={classes.tableHistoryHeader} p={0.1}>
          <Typography className={classes.historyDate}>
            <strong>{`${name} (${input.unit})`}</strong>
          </Typography>
        </Box>
      }
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="history table">
          <TableHead>
            <TableRow>
              {chart
                ? <TableCell className={classes.tableHistoryHeader} scope="row">
                  <Typography className={classes.historyDate}>
                    <strong>{name}</strong>
                    {(input?.value_range?.min || input?.value_range?.max || input.unit) &&
                      <Typography component={'span'} pl={0.5} className={classes.subHeaderTitle} variant="subtitle2" color="textSecondary">
                        <small>
                          (
                          {input?.value_range.min || ''}
                          {input?.value_range?.min && input?.value_range?.max ? '-' : ''}{input?.value_range?.max}
                          {input?.value_range?.min && input?.value_range?.max ? ` ` : ''}{input.unit}
                          )
                        </small>
                      </Typography>
                    }
                  </Typography>
                </TableCell>
                : <React.Fragment key={`target-device-header`}>
                  {!loading &&
                    <React.Fragment key={`target-device-header`}>
                      <TableCell className={classes.tablePtHistory} style={{ width: '34%' }} scope="row">
                        <Typography className={classes.historyDate}>{'Date'}
                          <IconButton
                            size="small"
                            disabled={historyData?.length < 1}
                            onClick={() => sortHistroyByType('date')}
                          >
                            {getSortIcon(sortList.date)}
                          </IconButton>
                          {name.includes('weight') &&
                            <IconButton
                              size="small"
                              onClick={() => syncDeviceData()}
                              className={classes.expressModeBtn}
                              sx={{ padding: '0px' }}
                            >
                              <SyncIcon className={classes.infoIconFont} /><strong>Sync Data</strong>
                            </IconButton>
                          }
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ width: '30%', cursor: 'pointer' }}>
                        Range
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ width: '35%' }}>
                        Value
                      </TableCell>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
              <TableCell className={classes.tableHeader} align="right" >
                <IconButton disabled={historyData?.length < 1} size="small" onClick={() => onChartClick()}>
                  {chart
                    ? <MenuIcon />
                    : <EqualizerIcon fontSize="small"></EqualizerIcon>
                  }
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {loading
          ? <Grid container direction="row" justifyContent="center" alignItems="center">
            <Box p={1}><CircularProgress size="2rem" /></Box>
          </Grid>
          : <Box>
            {checkCondition(
              historyData.length > 0,
              checkCondition(chart,
                <Box className={classes.historyData}>
                  <ChartComponent
                    type='box'
                    labels={labels}
                    xLabels={true}
                    color='#FF7015'
                    yValues={chartData.map((d: any) => d.value)}
                    chartLabel={`${name}(${input.unit})`}
                    height={200}
                    yMax={getValueRange(input?.value_range?.max, 'max')}
                    yMin={getValueRange(input?.value_range?.min, 'min')}
                  />
                </Box>,
                <TableContainer component={Paper} className={classes.historyTableScroll}>
                  <Table className={classes.historyData} aria-label="simple table">
                    <TableBody>
                      {historyData.map((row: any, i: any) => (
                        <TableRow key={i}>
                          <TableCell className={classes.tablePaddingPT} scope="row" sx={{ width: '30%' }}>
                            <Typography color={'textSecondary'} className={classes.desc}>
                              <small>{moment.unix(row.date_recorded).format('MMM DD YYYY hh:mm a')}</small>
                            </Typography>
                          </TableCell>
                          <TableCell className={`${classes.tablePaddingPT} ${classes.desc}`} sx={{ width: '28%' }}>
                            <Typography color={'textSecondary'} className={classes.desc}>
                              ({row.value_range.min}{row.value_range.min && row.value_range.max ? '-' : ''}{row.value_range.max}
                              <small>{row.value_range.min || row.value_range.max ? ' ' : ''}{row.unit}</small>)
                            </Typography>
                          </TableCell>
                          <TableCell className={`${classes.tablePaddingPT}`} sx={{ width: '30%' }}>
                            <Typography color={'textSecondary'} className={classes.desc} textAlign={'left'}>
                              {row.value}{getTag(row)}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableCellIconPtHistory} align="right" sx={{ width: '12%' }}>
                            {input?.ref === 'measurements' &&
                              <>
                                <IconButton
                                  className={classes.targetIcons}
                                  disabled={Helper.isPrgInActive(program_enrollments, input?.programs)}
                                  onClick={() => outer(
                                    {
                                      id: input.id,
                                      historyData: { ...row, id: input.id, programs: input?.programs }
                                    },
                                    'edit_history')}>
                                  <EditIcon className={classes.infoIconFont} />
                                </IconButton>
                                <IconButton
                                  className={classes.targetIcons}
                                  disabled={Helper.isPrgInActive(program_enrollments, input?.programs)}
                                  onClick={() => outer({
                                    id: input.id,
                                    historyData: row
                                  }, 'delete')}>
                                  <DeleteIcon className={classes.ptHistoryIconSize} />
                                </IconButton>
                              </>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>),
              <Box p={2}>
                <EmptyCardComponent />
              </Box>
            )}
          </Box>
        }
        <Box className={classes.measurementButton} p={0.4}>
          <Grid container xs={12} direction="row" justifyContent="center" alignItems="center">
            <Typography>
              <Button
                onClick={() => onCloseClick('view')}
                size="small"
                className={classes.corfirmCancelButtonStyle}
              >
                <strong>Close</strong>
              </Button>
            </Typography>
          </Grid>
        </Box>
      </TableContainer>
    </Card>
  </Grid>
}

export default withStyles(stylesheet)(HistoryPDIComponent)
