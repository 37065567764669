import { createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#ff701594',
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#FF7015'
          }
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#FF7015',
          '.MuiCircularProgress-circle': {
            stroke: '#FF7015'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiTypography-colorSecondary': {
            color: '#f50057 !important'
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26) !important',
          },
          '.MuiListItemIcon-root': {
            minWidth: '1.8rem !important',
          }
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderColor: 'lightgray !important',

          '& .MuiButtonGroup-firstButton': {
            borderColor: 'lightgray !important',
          },
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.8125rem',
          textTransform: 'none',

          '&.MuiButton-contained': {
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '0.8125rem',
            backgroundColor: '#e0e0e0'
          },
          '&.MuiButton-outlined': {
            textTransform: 'none',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              color: '#FFFFFF',
              backgroundColor: '#D87C4E',
            },
            color: '#D87C4E',
            border: '#D87C4E solid .1rem',
            padding: '0.3125rem 1.5rem',
          },
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
            border: '1px solid rgba(0, 0, 0, 0.12) !important',
          },
          '&.MuiButton-sizeSmall': {
            fontSize: '0.8125rem',
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.MuiAccordion-root': {
            border: 'none',
            boxShadow: 'none',
            paddingTop: '0px',
            backgroundColor: '#f8f9fa',
            '&.MuiAccordion-root.Mui-expanded': {
              margin: '0px !important',
            },
            '&:not(:last-child)': {
              borderBottom: 0,
            },
            '&:before': {
              display: 'none',
            },
            '&$expanded': {
              margin: 'auto',
            },
          },
          '.MuiSvgIcon-root': {
            fontSize: '1.25rem'
          },
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.MuiAccordionSummary-root': {
            marginBottom: -2,
            flexDirection: 'row-reverse',
            justifyContent: 'flex-start',
            alignItems: 'center',
            minHeight: 6,
            padding: '5px 1rem 0px 0px',
          },
          '.MuiAccordionSummary-content': {
            minHeight: 6,
            margin: '1px 0px !important',
            padding: '0.1rem',
          },
          '.Mui-expanded': {
            margin: '1px 0 !important',
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotate(270deg)',
          },
          '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
          },
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          '&.MuiAccordionDetails-root': {
            padding: '0px 10px 0px 8px !important',
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.MuiRadio-root': {
            color: 'rgba(0, 0, 0, 0.54)',
            cursor: 'pointer',
            fontSize: '0.8rem',
            "& svg": {
              width: "0.8em",
              height: "0.8em"
            },
            '&.Mui-checked': {
              color: '#f16522',
            },
          }
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          // color: '#f16522',
          width: '80%',
          height: '2px',
          padding: '13px 0',
          fontSize: '0.8rem',
          marginBottom: '35px',

          '& .MuiSlider-rail': {
            backgroundColor: '#CCCCCC',
          },
          '& .MuiSlider-mark': {
            width: '2px',
            height: '6px',
            margin: '0px',
            border: 'none',
          },
          '& .MuiSlider-track': {
            height: '0px'
          },
          '& .MuiSlider-thumb': {
            height: '15px',
            width: '15px ',
            border: 'none',
            marginTop: '0px',
          },
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.MuiInputBase - input': {
            fontSize: '0.8rem !important',
            '&:after': {
              border: '1px solid #666666'
            }
          },
          '&.MuiInput-root': {
            fontSize: '0.8rem !important',
            '&:after': {
              border: '1px solid #666666'
            }
          },
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.MuiTab-root': {
            padding: '6px 12px',
            textTransform: 'none',
            color: '#080808',
            fontSize: '0.75rem',
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#FF7015 !important',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiList-padding': {
            paddingTop: '5px',
            paddingBottom: '5px'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-root': {

          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '&.MuiDialogContent-root': {
            padding: '20px !important',
          },
          '&.MuiDialog-root': {
            width: '62rem',
            height: 'fit-content',
          },
          '&. MuiDialog-scrollPaper': {
            backgroundColor: 'gray',
            width: '100%',
            height: 'fit-content'
          },
          '&.MuiDialog-paper': {
            margin: '0px'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          padding: 1,
        },
        option: {
          fontSize: '0.8rem !important'
        },

      }
    }
  }
});