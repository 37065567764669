import React, { useRef, useEffect, useState } from 'react'
import {
  Grid, LinearProgress, IconButton, DialogTitle, DialogContent, Dialog, Box, Typography, Card, CardContent,
} from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ClassNameMap, withStyles } from '@mui/styles'
import Draggable from "react-draggable"
import DetailCardComponent from '../detail-card.component'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'
interface IProps {
  open: boolean,
  item: any,
  handleDialogClose: any,
  classes: ClassNameMap
}

const PendingItemsDialogComponent: React.FC<IProps> = ({ open, item, handleDialogClose, classes }) => {
  const actionPrgRef = useRef(null) as any
  let patientDetails = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const [patientDetail, setPatientDetail] = useState({} as any)
  const patientDetailStatus: any = useSelector((state: RootState) => state.patientDetail.status)
  const { updatePatientData } = useUpdateDataAction(patientDetails.id)

  useEffect(() => {
    updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
    setPatientDetail({ ...patientDetails, 'isActionItems': true, actionProgram: item?.program })
  }, [])

  return (
    <Draggable ref={actionPrgRef} defaultPosition={{ x: 0, y: 0 }} handle='.action-item-dialog'>
      <Dialog
        id={'action-program'}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="Actio items"
        hideBackdrop
        disableScrollLock
        onKeyUp={(e) => {
          if (e.key === 'Enter')
            handleDialogClose()
        }}
        sx={{
          position: "absolute",
          left: '15rem',
          top: '4%'
        }}
      >
        <DialogTitle className={classes.addPatientQuestionHeaderSection} classes={{ root: 'action-item-dialog' }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography color="textSecondary">
                <small>{`Pending items`}</small>
              </Typography>
              <Typography id="modal-modal-title" variant="h6">
                <strong>{item?.title}</strong>
              </Typography>
            </Grid>
            <Grid item >
              <IconButton size="small" onClick={handleDialogClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {patientDetailStatus === 'loading' ?
          <Grid item><LinearProgress /></Grid>
          : <DialogContent sx={{ padding: '0px 5px 5px 5px !important', backgroundColor: '#F1F1F6' }}>
            <Box className={classes.dialogHeaderBox}>
              <Grid spacing={1} container className={classes.containerPadding}>
                <Grid item className={classes.fullWidth}>
                  {item?.sfCaseStatus !== '' &&
                    <Card variant="outlined" className={classes.compactCard}>
                      <CardContent className={[classes.confirmCardBackground, classes.cardsContentPadding].join(' ')}>
                        <Typography variant="body2" className={classes.desc}>{item?.sfCaseStatus}</Typography>
                      </CardContent>
                    </Card>
                  }
                  {patientDetails.needs_update &&
                    <Card variant="outlined" className={classes.compactCard}>
                      <CardContent className={[classes.confirmCardBackground, classes.cardsContentPadding].join(' ')}>
                        <Typography variant="body2" className={classes.desc}>{`Patient profile is not updated.`}</Typography>
                      </CardContent>
                    </Card>
                  }
                  <Grid container direction="row" justifyContent="flex-start" alignItems="baseline" spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box ml={1} mt={1} className={classes.topTHeader}>
                        <Grid container spacing={1}>
                          <Grid item >
                            <Typography variant="h6" color="textSecondary" className={classes.headerTitle}>
                              Measurements
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <DetailCardComponent
                        patientInfo={patientDetail}
                        headerName=""
                        parent="ME-PTR"
                      />
                      <DetailCardComponent
                        patientInfo={patientDetail}
                        headerName="Program Checks"
                        parent="ME-PDI"
                      />
                      <DetailCardComponent
                        patientInfo={patientDetail}
                        headerName="Questionnaires"
                        parent="ME-CAL"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} style={{ minWidth: '8rem' }}>
                      <Box ml={1} mt={1} className={classes.topTHeader}>
                        <Typography variant="h6" color="textSecondary" className={classes.headerTitle}>
                          Treatments
                        </Typography>
                      </Box>
                      <DetailCardComponent
                        patientInfo={patientDetail}
                        headerName="Care Plans"
                        parent="MD-PRMD"
                      />
                      <DetailCardComponent
                        patientInfo={patientDetail}
                        headerName="Monitors"
                        parent="MD-PRMT"
                      />
                      <DetailCardComponent
                        patientInfo={patientDetail}
                        headerName="Lifestyle Plans"
                        parent="MD-LS"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box ml={1} mt={1} className={classes.topTHeader}>
                        <Typography variant="h6" color="textSecondary" className={classes.headerTitle}>
                          Conditions and Problems
                        </Typography>
                      </Box>
                      <DetailCardComponent
                        patientInfo={patientDetail}
                        headerName="Diagnosis and Coding Assessments"
                        parent="CO-DIA"
                      />
                      <DetailCardComponent
                        patientInfo={patientDetail}
                        headerName="Relevant Comorbidities"
                        parent="CO-PSC"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        }
      </Dialog>
    </Draggable>
  );
};

export default withStyles(stylesheet)(PendingItemsDialogComponent)
