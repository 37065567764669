import React, { memo, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { RouteComponentProps } from 'react-router-dom';
import PatientListComponent from '../patients/patient-listing.component';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import PatientHomePageComponent from './patient-home-page.component';
import Helper from '../../utils/helper';
import { environment } from '../../environments/environment';
import { PatientService } from '../../services/patient.service';
import { toast } from 'react-toastify';

interface IProps extends RouteComponentProps {
  history: any;
  location: any;
  match: any;
}

const HomePageComponent: React.FC<IProps> = ({ history, location, match }) => {
  const loggedInUser = useSelector(
    (state: RootState) => state.commonData.loggedInUser
  )
  const patientService = new PatientService()

  useEffect(() => {
    if (localStorage.getItem('LOGIN_REDIRECT') === 'true') {
      localStorage.setItem("LOGIN_REDIRECT", '')
      getKBVersion()
    }
  }, [])

  const getKBVersion = () => {
    patientService.getKBVersion().then((res: any) => { }).catch((error: any) => {
      toast.warning(error?.response?.data?.message, Helper.bottom_center())
    })
  }

  return (
    <Grid container spacing={0} data-testid='homepage-testId'>
      <Grid container item lg={12} direction='column'>
        <>
          {!loggedInUser?.roles && <Box pl={1}>Loading...</Box>}
          {Helper.checkGroup(environment.okta.groups, loggedInUser?.roles) &&
            <PatientListComponent
              history={history}
              location={location}
              match={match}
            />
          }
          {loggedInUser?.roles?.includes('PATIENT') &&
            <PatientHomePageComponent history={history} location={location} match={match} />
          }
        </>
      </Grid>
    </Grid>
  );
};

export default memo(HomePageComponent);
