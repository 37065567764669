import React, { useEffect, useState, useRef } from 'react'
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress,
  Dialog, DialogTitle, Grid, IconButton, Tooltip, Typography,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import Draggable from "react-draggable"
import InfoIcon from '@mui/icons-material/Info'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import stylesheet from '../detail-container.stylesheet'
import QuestionnaireComponent from '../../../questionnaires/questionnaire.component'
import { PatientService } from '../../../../services/patient.service'
import CardLayoutComponent from '../common/card-layout.component'
import NotesComponent from '../common/notes.component'
import { useCheckConfirmCount } from '../../../../utils/use-checkconfirmationcount-hook'
import { useDispatchAPIs } from "../../../../utils/use-dispatchApis-hook";
import { RootState, useAppThunkDispatch } from '../../../../redux/store'
import ReferralComponent from '../common/referral-card.component'
import { useReferralAction } from '../../../../utils/referral-hook'
import { useOverrideAction } from '../../../../utils/override-hook'
import EmptyCardComponent from "../common/empty-card.component"
import Helper from '../../../../utils/helper'
import ManualOverrideComponent from "../common/manual-override.component"
import CategoryQuesionnariesComponent from '../../../questionnaires/category-quesionnaries.component'
import DialogTitleHeaderComponent from '../common/dialog-title-header.component'
import ColorBubbleComponent from '../common/color-bubble.component'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

interface IProps {
  input: any
  classes: ClassNameMap
}

const CalculatorsComponent: React.FC<IProps> = ({ input, classes }) => {
  const [data, setData] = useState([] as any)
  const [category, setCategory] = useState([] as any)
  const [open, setOpen] = useState(false)
  const [openCategory, setOpenCategory] = useState(false)
  const [currCategory, setCurrCategory] = useState({} as any)
  const [selectedCal, setSelectedCal] = useState({} as any)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isConfirmSubmit, setIsConfirmSubmit] = useState(false)
  const [isDiagnosisSort, setIsDiagnosisSort] = useState(false)
  const [selectedNotes, setSelectedNotes] = useState([] as number[])
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const [, setCount] = useCheckConfirmCount(patientDetail.id)
  const selectedProgram = useSelector((state: RootState) => state.commonData.selectedProgram)
  const program_enrollments = useSelector((state: RootState) => state.patientDetail.patientDetail?.program_enrollments || [])
  const { onSetView, onDataSubmit } = useReferralAction()
  const { onSetOverView, onDataOverSubmit } = useOverrideAction()
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const inputEl = useRef(null) as any
  const calRef = useRef(null) as any
  const { multipleProgramCounts } = useDispatchAPIs(patientDetail.id)
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)

  useEffect(() => {
    initialiseData()
  }, [patientDetail])

  useEffect(() => {
    setCategory(Object.keys(uiResource?.QUES_CATEGORY)?.map((_key: any) => uiResource?.QUES_CATEGORY[_key]))
  }, [])

  const initialiseData = () => {
    let calData = input?.isActionItems === true
      ? patientDetail?.measurements?.calculators
        ?.filter((i: any) => i.needs_confirmation === true &&
          i.programs?.includes(input?.actionProgram) &&
          !i.overridden_programs?.includes(input?.actionProgram))
      : patientDetail?.measurements?.calculators

    setData(
      calData?.map((e: any) => {
        return { ...e, mode: 'view' }
      })
    )
  }

  const setMode = (_id: string, _mode: string) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: _mode } : el))
    )
  }

  const handleClickOpen = (_id: string) => {
    setSelectedCal(data.find((e: { id: string }) => e.id === _id))
    setOpen(true)
  }

  const handleCategoryEditOpen = (key: string) => {
    setCurrCategory({ key: key })
    setOpenCategory(true)
  }

  const handleDiagnosisEditOpen = (prg: any) => {
    setCurrCategory({ key: 'diagnosis', program: prg })
    setOpenCategory(true)
  }

  const handleCategoryEditClose = (currData: []) => {
    setCurrCategory({})
    let updatedData = []
    if (Object.keys(currData)?.length > 0) {
      updatedData = data.map((e: any) => {
        const currObj = currData?.find((r: any) => e.id === r.id) || {}
        return (Object.keys(currObj)?.length > 0)
          ? { ...currObj, mode: 'view' }
          : { ...e }
      })
      setData(updatedData)
    }
    setOpenCategory(false)
  }

  const handleClose = () => {
    setOpen(false)
    setIsConfirmSubmit(false)
    setSelectedCal({})
  }

  const getQuestionnarieName = (id: any) => data.find((i: any) => i.id === id)?.display_name || id

  const handleSave = (_type: string, res: any) => {
    setOpen(false)
    setIsSubmit(false)
    setIsConfirmSubmit(false)
    if (Object.keys(res)?.length <= 0) {
      toast.error(
        `Failed to ${_type === 'confirm' ? 'confirm' : 'save'} ${selectedCal.id ? getQuestionnarieName(selectedCal.id) : ''}.`,
        Helper.bottom_center()
      )
      return
    }
    updatePatientData({ isMeasurement: true })
  }

  const handleIsSubmit = (flag: boolean) => setIsSubmit(flag)

  const setIcons = () => {
    return input?.isActionItems === true
      ? []
      : [
        { iconType: 'edit', callback: handleClickOpen },
        { iconType: 'description', callback: onNotesClick },
        { iconType: 'more', callback: {} },
      ]
  }

  const rightHeaderCal = (d: any) => <></>

  const bottomTextCal = (d: any) => {
    return (d.is_referred)
      ? moment.unix(d.referred_on).format("MM/DD/YY")
      : d.created_on
        ? moment.unix(d.created_on).format('MM/DD/YY')
        : ''
  }

  const onNotesClick = (_id: any) => {
    if (selectedNotes.includes(+_id)) {
      setSelectedNotes([...selectedNotes.filter((f) => f !== +_id)])
    } else {
      setSelectedNotes([...selectedNotes, +_id])
    }
  }

  const onRefClick = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }
  const onRefSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any) => {
    setData(onDataSubmit(_id, date, is_referred, referral_id, data))
  }

  const onOverrideSubmit = (_id: any, date: number, is_overridden: boolean, overridenPrograms: any[]) => {
    const updatedData = onDataOverSubmit(_id, date, is_overridden, data, overridenPrograms)
    setData(updatedData)
    if (is_overridden) {
      setCount(true, false)
      multipleProgramCounts(
        [{
          "previous": true,
          "current": false,
          "programs": overridenPrograms
        }])
    } else {
      setCount(false, true)
      multipleProgramCounts(
        [{
          "previous": false,
          "current": true,
          "programs": overridenPrograms
        }])
    }
  }

  const onOverrideClick = (_id: any, _mode: string) => setData(onSetOverView(_id, _mode, data))

  const capitalizeFirstLetter = (str: any) =>
    str && str?.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : str

  const getDiagnosisRes = (d: any) => d?.first_response === 'Yes' ? `(${d?.first_response})` : ''

  const getSeverity = (severity: any, isTitle: '') => {
    return severity && severity?.value !== null ?
      <>
        {`${isTitle} (severity:${severity?.value}${severity?.max_value ? `/${severity?.max_value}` : ''})`}
        <Tooltip
          data-testid='severity-testId'
          title={severity?.question_text ? severity?.question_text : 'Details not available'}
          placement="bottom">
          <IconButton size='small' className={classes.infoIcon} sx={{ padding: '0px' }}>
            <InfoIcon className={classes.infoIconFont} />
          </IconButton>
        </Tooltip>
      </>
      : ''
  }

  const isCategoryNeedsUpdate = (data: any, cat: any) => {
    if (cat)
      data = data?.filter((i: any) => i.category === cat)
    return data?.filter((i: any) => i.category === cat)?.some((i: any) => i.needs_confirmation)
  }

  const renderCalculatorCard = (data: any, cat: any, prg = '') => {
    if (cat) {
      data = (prg !== '')
        ? data?.filter((i: any) => i.category === cat && (i.programs || []).includes(prg))
        : data?.filter((i: any) => i.category === cat)
    }
    data = [...data]?.sort((a: any, b: any) => (a?.display_name).toLowerCase() > (b?.display_name).toLowerCase() ? 1 : -1)

    if (cat == 'diagnosis' && isDiagnosisSort) {
      data = [...data]?.sort((a: any, b: any) => a?.first_response === b?.first_response ? 0 : a?.first_response === 'Yes' ? -1 : 1)
    }

    return data?.map((d: any) => {
      return <React.Fragment key={`calculator-${cat}-${d.id}`}>
        {d.mode === 'view' && d.programs?.filter((f: string) => selectedProgram.includes(f))?.length > 0 && (
          <>
            <CardLayoutComponent
              id={d.id}
              name={d?.name}
              leftHeader={`${d?.display_name}`}
              rightHeader={rightHeaderCal(d)}
              subHeader={cat === 'diagnosis' ? getDiagnosisRes(d) : getSeverity(d?.severity, '')}
              bottomText={bottomTextCal(d)}
              needs_confirmation={d.needs_confirmation || false}
              needs_update={d.needs_update || false}
              icons={setIcons()}
              programs={prg !== '' ? [prg] : (d?.programs || [])}
              overridenPrograms={d.overridden_programs}
              onRefClick={() => onRefClick(d.id, 'ref')}
              onOverrideClick={() => onOverrideClick(d.id, 'override')}
              is_referred={d.is_referred}
              is_overridden={d.is_overridden || undefined}
              entityRef={'calculator'}
              patientId={patientDetail.id}
              category={cat === 'diagnosis' ? 'conditions' : 'measurements'}
            />
            {selectedNotes.includes(d.id) && (
              <NotesComponent
                entityId={d.id}
                entityRef={'calculator'}
                cancelBtn={true}
                onCancel={onNotesClick}
              >
                Notes
              </NotesComponent>
            )}
          </>
        )}
        {d.id === selectedCal.id && isSubmit && (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Box mb={1}><CircularProgress size="2rem" /></Box>
          </Grid>
        )}
        {d.mode === 'ref' && (
          <ReferralComponent
            entityRef='calculator'
            input={d}
            patientId={patientDetail.id}
            leftHeader={d.display_name}
            rightHeader={rightHeaderCal(d)}
            programs={d.programs}
            closeRef={() => onRefClick(d.id, 'view')}
            onRefSubmit={onRefSubmit}
          />
        )}
        {d.mode === 'override' && (
          <ManualOverrideComponent
            entityRef='calculator'
            input={d}
            leftHeader={d.display_name}
            rightHeader={rightHeaderCal(d)}
            programs={d.programs}
            closeOverride={() => onOverrideClick(d.id, 'view')}
            onOverrideSubmit={onOverrideSubmit}
          />
        )}
      </React.Fragment>
    })
  }

  const isDataByCategory = (category: any) => {
    const currList = data?.filter((i: any) => i.category === category)
    return (currList && currList?.length > 0) ? true : false
  }

  const isPrgUpdate = (cat: any, prg: any) => {
    return data
      ?.filter((i: any) => i.category === cat && (i.programs || []).includes(prg))
      ?.some((i: any) => i.needs_confirmation)
  }

  const getPrgDisplayName = (key: any) => program_enrollments.find((e: any) => e.program === key)?.program_display_name || key

  const getQuestionnarieTitle = (currQ: any) => {
    const name = capitalizeFirstLetter(currQ && currQ?.display_name ? currQ.display_name : currQ?.name)
    return currQ.category === "diagnosis"
      ? `${name} ${getDiagnosisRes(currQ)}`
      : currQ?.severity && currQ?.severity?.value !== null
        ? getSeverity(currQ?.severity, name)
        : name
  }

  const getSortIcon = (d: any) => {
    if (d === 'desc') {
      return <ArrowDropDownIcon className={classes.subHeadername} />
    } else if (d === 'asc') {
      return <ArrowDropUpIcon className={classes.programTargetEditIcon} />
    } else {
      return <UnfoldMoreIcon sx={{ padding: '0px !important' }} className={classes.programTargetEditIcon} />
    }
  }

  const handleDiagnosisSort = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDiagnosisSort(!isDiagnosisSort)
  }

  return (
    <>
      <Grid container direction="row">
        <Grid item className={classes.fullWidth}>
          {input?.type === 'diagnosis' ?
            <Grid container className={classes.zeroTopPadding}>
              <Grid item className={classes.fullWidth}>
                {data && selectedProgram?.length > 0 && selectedProgram?.map((key: any, index: any) => (
                  <React.Fragment key={`${key}-${index}`}>
                    {((input?.actionProgram && key === input?.actionProgram && isPrgUpdate('diagnosis', key))
                      || !input?.actionProgram) &&
                      <Accordion square expanded={input?.actionProgram} className={classes.patientAccrodionBg}>
                        <AccordionSummary
                          ref={inputEl}
                          expandIcon={<ExpandMoreIcon className={classes.patientAccrodionExpandIcon} />}
                          className={classes.calAccordionSummary}
                        >
                          <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item xs={8}>
                              <Typography className={classes.subTitle}>
                                {getPrgDisplayName(key)}
                                <ColorBubbleComponent selectedProgramList={[key]} />
                                {!input?.actionProgram &&
                                  <Button
                                    variant="text"
                                    title='Sort'
                                    className={classes.expressModeBtn}
                                    onClick={(e) => handleDiagnosisSort(e)}
                                    sx={{ padding: '0px !important', minWidth: '1.5rem' }}
                                  >
                                    {getSortIcon('')}
                                  </Button>
                                }
                              </Typography>
                            </Grid>
                            {!input?.actionProgram &&
                              <Button
                                variant="text"
                                title='Edit'
                                disabled={Helper.isPrgInActive(program_enrollments, [key])}
                                className={classes.expressModeBtn}
                                sx={{
                                  padding: '0rem !important',
                                  color: isPrgUpdate('diagnosis', key)
                                    ? '#FF7015 !important'
                                    : '#757575',
                                  '&.Mui-disabled': {
                                    border: 'none !important',
                                  }
                                }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  handleDiagnosisEditOpen(key)
                                }}
                              >
                                <EditIcon className={classes.subHeadername} />
                              </Button>
                            }
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>
                            <Grid item className={classes.fullWidth}>
                              {renderCalculatorCard(data, 'diagnosis', key)}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    }
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
            : <>
              {category && category?.length > 0 && category.map((key: any, index: any) => (
                <React.Fragment key={`${key}-${index}`}>
                  {key !== 'diagnosis' && isDataByCategory(key) &&
                    <Accordion square defaultExpanded className={classes.patientAccrodionBg}>
                      <AccordionSummary
                        ref={inputEl}
                        expandIcon={<ExpandMoreIcon className={classes.patientAccrodionExpandIcon} />}
                        className={classes.calAccordionSummary}
                      >
                        <Grid container justifyContent="space-between" alignItems="center">
                          <Typography className={classes.subTitle}>{capitalizeFirstLetter(key)}</Typography>
                          {!input?.actionProgram &&
                            <Button
                              variant="text"
                              title='Edit'
                              className={classes.expressModeBtn}
                              sx={{
                                paddingLeft: '2rem',
                                color: isCategoryNeedsUpdate(data, key)
                                  ? '#FF7015 !important'
                                  : '#757575'
                              }}
                              onClick={(e: any) => {
                                e.preventDefault()
                                e.stopPropagation()
                                handleCategoryEditOpen(key)
                              }}
                            >
                              <EditIcon className={classes.subHeadername} />
                            </Button>
                          }
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item className={classes.fullWidth}>
                            {renderCalculatorCard(data, key, '')}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  }
                </React.Fragment>
              ))}
            </>
          }
        </Grid>
        {data?.length == 0 && <EmptyCardComponent />}
      </Grid>
      {openCategory && (
        <CategoryQuesionnariesComponent
          open={openCategory}
          title={
            currCategory?.key === 'diagnosis'
              ? `Diagnosis and Coding Assessments -  ${getPrgDisplayName(currCategory?.program)}`
              : capitalizeFirstLetter(currCategory?.key)}
          item={
            currCategory?.key === 'diagnosis'
              ? data?.filter((i: any) => i.category === currCategory?.key && (i?.programs || []).includes(currCategory?.program))
              : data?.filter((i: any) => i.category === currCategory?.key)}
          handleClose={handleCategoryEditClose}
        />
      )}
      {open && (
        <Draggable ref={calRef} defaultPosition={{ x: 280, y: 50 }} handle='.questionnarie-dialog'>
          <Dialog
            id={'cal-id'}
            fullWidth={true}
            maxWidth="md"
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            aria-labelledby="calcluator-questionnaire"
            hideBackdrop
            disableScrollLock
            onKeyUp={(e) => {
              if (e.key === 'Enter')
                setIsConfirmSubmit(true)
            }}
            sx={{
              position: "absolute",
              top: '10%'
            }}
          >
            <QuestionnaireComponent
              data={{
                name: capitalizeFirstLetter(selectedCal?.display_name || selectedCal?.name),
                title: getQuestionnarieTitle(selectedCal),
                severity: { ...selectedCal?.severity },
                category: selectedCal.category,
              }}
              pId={patientDetail.id + ''}
              qId={selectedCal?.questionnaire_id + ''}
              qu={selectedCal.id}
              handleIsSubmit={handleIsSubmit}
              handleClose={handleClose}
              handleSave={handleSave}
              isConfirmSubmit={isConfirmSubmit}
              isSubmit={isSubmit}
              needsConfirmation={selectedCal?.needs_confirmation}
            />
          </Dialog>
        </Draggable>
      )}
    </>
  )
}

export default withStyles(stylesheet)(CalculatorsComponent)
