import React, { useRef, useState } from 'react'
import {
  Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress,
  FormControl, Typography, MenuItem, Select, Box,
} from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, withStyles } from '@mui/styles'
import Helper from '../../../../utils/helper'
import { PatientService } from '../../../../services/patient.service'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import DialogTitleHeaderComponent from '../common/dialog-title-header.component'
import Draggable from 'react-draggable'
import { environment } from '../../../../environments/environment'
import moment from 'moment'

interface IProps {
  openExtract: any
  handleCloseExtract: any
  classes: ClassNameMap
}

const ExtractPatientComponent: React.FC<IProps> = ({ openExtract, handleCloseExtract, classes }) => {
  const patientService = new PatientService()
  const [loading, setLoading] = useState(false)
  const [hospital, setHospital] = useState('' as any)
  const addPatientRef = useRef(null) as any
  const hospitals = useSelector((state: RootState) => state.metaData.metadata.HOSPITALS)

  const handleChange = (e: any) => setHospital(e.target.value || '')

  const handleSubmit = () => {
    setLoading(true)
    patientService.extractHospitalRXReport(hospital).then((res: any) => {
      Helper.handleReportFile(res?.data, 'rx_report')
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))

    patientService.extractHospitalBPReport(hospital).then((res: any) => {
      Helper.handleReportFile(res?.data, 'bp_report')
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))

    patientService.extractHospitalInteractionReport(hospital).then((res: any) => {
      Helper.handleReportFile(res?.data, 'interaction_report')
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))

    patientService.extractHospitalLabReport(hospital).then((res: any) => {
      Helper.handleReportFile(res?.data, 'lab_report')
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))

    setLoading(false)
  }

  return (
    <Draggable ref={addPatientRef} handle='.export-dialog'>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openExtract}
        onClose={handleCloseExtract}
        aria-labelledby={'Export'}
        title={'Export'}
        hideBackdrop
        disableScrollLock
        onKeyUp={(e) => {
          if (e.key === 'Enter' && hospital !== '')
            handleSubmit()
        }}
        sx={{
          position: "absolute",
          left: '15%',
          top: '15%'
        }}
      >
        <DialogTitle className={classes.addPatientQuestionHeaderSection} classes={{ root: 'export-dialog' }}>
          <DialogTitleHeaderComponent title='Extract' handleDialogClose={handleCloseExtract} />
        </DialogTitle>
        <Grid item>{loading && <LinearProgress />}</Grid>
        <DialogContent className={classes.addPatientDialogContent}>
          <Box className={classes.containerPadding}>
            <FormControl className={classes.formControl}>
              <Typography className={classes.desc} variant='subtitle2'>Select hospital</Typography>
              <Select
                sx={{ width: '10rem' }}
                variant="standard"
                label='Select hospital'
                onChange={(e) => handleChange(e)}
              >
                {hospitals && Object.keys(hospitals)?.sort()?.map((item: any) =>
                  <MenuItem key={item} value={item} className={classes.desc}>
                    {hospitals[item]}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.containerPadding} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                disabled={loading || hospital === ''}
                onClick={() => handleSubmit()}
                component="label"
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" size="small" onClick={handleCloseExtract}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Draggable>
  )
}

export default withStyles(stylesheet)(ExtractPatientComponent)