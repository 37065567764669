import React, { useState } from 'react'
import {
  Box, Button, ButtonGroup, Card, CardContent, CircularProgress, Grid, Typography, Badge,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import ColorBubbleComponent from '../common/color-bubble.component'
import { checkCondition } from '../common/condition-check'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import { useCurrentUser } from '../../../../utils/use-current-user'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

interface IProps {
  patientId: string
  input: any
  name: string
  outer: any
  outerSubmit: any
  classes: ClassNameMap
}

const QuestionPSCComponent: React.FC<IProps> = ({ patientId, input, name, outer, outerSubmit, classes, }) => {
  const [currentOption, setCurrentOption] = useState(input.score)
  const [loading, setLoading] = useState(false)
  const patientService = new PatientService()
  const { isNavigator } = useCurrentUser()

  const onBtnChange = (_input: string) => setCurrentOption(_input)

  const onConfirmation = (
    _id: string,
    _patientId: string,
    _mode: string,
    _typeOut: string,
    score: string,
    _is_score_different: boolean
  ) => {
    const payload = {
      entity_ref: 'condition_evaluation',
      entity_id: _id,
      confirmed_by: 1, // mocked for future scope
    }
    patientService
      .PostConfirmations(payload)
      .then((response: any) => {
        setLoading(false)
        outerSubmit(
          _id,
          _mode,
          _typeOut,
          score,
          false,
          response.data?.confirmation?.confirmed_on,
          _is_score_different
        )
      })
      .catch((err: any) => {
        setLoading(false)
        toast.error(Helper.getErrorText(err), {
          position: 'top-right',
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        })
      })
  }

  const onBtnSubmit = (_mode: string, _typeOut: string) => {
    setLoading(true)
    onCloseClick('view')

    const payload = {
      provider_assessment: currentOption,
      data_reviewed: false, //reviewChecked,
      clarification_received: false, //checked,
      provider_notes: '', //notes,
      proposed_icds: [],
    }

    patientService
      .putProgramConditionsQuestionnaire(patientId, input.id, payload)
      .then((response: any) => {
        const dt = response.data
        if (_typeOut === 'confirm') {
          onConfirmation(input.id, patientId, _mode, _typeOut, dt.score, dt.is_score_different)
        } else {
          setLoading(false)
          outerSubmit(
            dt.id,
            _mode,
            _typeOut,
            dt.score,
            dt.needs_confirmation,
            dt.date_recorded,
            dt.is_score_different
          )
        }
      })
      .catch((errors: any) => {
        setLoading(false)
        toast.error(Helper.getErrorText(errors), {
          position: 'top-right',
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        })
      })
  }

  const onCloseClick = (mode: string) => outer(input.id, mode)

  return (
    <Grid xs={12} direction="row" className={classes.fullWidth}>
      <Box mb={1} className={classes.customCard}>
        <Card variant="outlined" className={classes.questionCardContent}>
          <CardContent className={classes.questionCardSubContent}>
            <Box className={classes.questionHeaderSection} p={1}>
              <Grid container xs={12} direction="row" alignItems="center">
                <Typography>
                  <strong>{name}</strong>
                  <ColorBubbleComponent selectedProgramList={input.programs || []} />
                </Typography>
              </Grid>
            </Box>
            {loading === true ?
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item m={1}><CircularProgress size="2rem" /></Grid>
              </Grid>
              : <>
                <Grid container pl={1} pr={1} mb={2} pt={1}>
                  <Grid item pb={1}>
                    <Typography variant="body2">
                      <strong>I agree with the primary diagnosis of {name}</strong>
                    </Typography>
                  </Grid>
                  <Grid container justifyContent='space-between'>
                    <Grid item>
                      <Badge
                        invisible={input.evaluation === 'yes' ? false : true}
                        badgeContent={<CheckCircleIcon fontSize="small" className={classes.bagdeIcon} />}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => onBtnChange('yes')}
                          className={checkCondition(currentOption === 'yes', [
                            classes.questionPSCButton,
                            classes.questionPSCButtonActive,
                            classes.badgeButton
                          ].join(' ')
                            , [classes.questionPSCButton, classes.badgeButton].join(' '))
                          }
                        > Yes
                        </Button>
                      </Badge>
                      <Badge
                        invisible={input.evaluation === 'no' ? false : true}
                        badgeContent={<CheckCircleIcon fontSize="small" className={classes.bagdeIcon} />}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => onBtnChange('no')}
                          className={checkCondition(currentOption === 'no', [
                            classes.questionPSCButton,
                            classes.questionPSCButtonActive,
                            classes.questionPSCNoButton
                          ].join(' '), [classes.questionPSCButton, classes.questionPSCNoButton].join(' '))
                          }
                        >
                          No
                        </Button>
                      </Badge>
                    </Grid>
                    <Grid item className={classes.badgeContainer}>
                      <Badge title={'Recommended'}
                        invisible={input.recommended_diagnosis ? false : true}
                        variant='standard'
                        badgeContent={'R'}
                        classes={{ badge: classes.customBadge }}
                      >
                        <Button disabled={true} variant="outlined" size="small" className={classes.badgeButton}>
                          <span className={classes.badgeButtonText}>{input.recommended_diagnosis}</span>
                        </Button>
                      </Badge>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container xs={12} direction="row" justifyContent="space-around" alignItems="center">
                  <ButtonGroup
                    className={classes.questionFullButtonStyle}
                    size="small"
                    variant="text"
                    aria-label="small outlined button group"
                  >
                    <Button
                      disabled={(input.score === currentOption || isNavigator)}
                      onClick={() => onBtnSubmit('view', 'save')}
                      className={classes.questionButtonWidth1}
                    >
                      <strong>Save</strong>
                    </Button>
                    <Button onClick={() => onCloseClick('view')} className={classes.questionButtonWidth1}>
                      <strong>Cancel</strong>
                    </Button>
                  </ButtonGroup>
                </Grid>
              </>
            }
          </CardContent>
        </Card>
      </Box>
    </Grid>
  )
}
export default withStyles(stylesheet)(QuestionPSCComponent)
