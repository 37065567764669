import React, { useState } from 'react'
import { CircularProgress, Menu, MenuItem, IconButton, Typography, List, ListItem } from '@mui/material'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import ProviderRecommendationComponent from './provider-recommendation.component'
import ConfirmationDialogComponent from '../common/confirmation-dialog.component'
import { useCurrentUser } from '../../../../utils/use-current-user'
import { PatientService } from '../../../../services/patient.service'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import { environment } from '../../../../environments/environment'
import MedChoicesComponent from '../common/med-choices.component'
import { useUpdateDataAction } from '../../../../utils/update-details-hook';

const ITEM_HEIGHT = 48

interface IProps {
  patientId: string
  title: string
  currPrg: any
  prgStatus: boolean
  currStage: any
  careplanId: string
  isRecomMed?: boolean
  onRefClick?: any
  onProviderSubmit: any
  classes: ClassNameMap
}

const LongMenu: React.FC<IProps> = ({ patientId, title, careplanId, isRecomMed, onRefClick, currPrg, prgStatus, currStage, onProviderSubmit, classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [addmedicationCard, setAddmedicationCard] = useState(false)
  const [currentPlanAction, setCurrentPlanAction] = useState()
  const [planloading, setPlanLoading] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmResetOpen, setConfirmResetOpen] = useState(false)
  const [isMedChoices, setIsMedChoices] = useState(false)
  const [isMedRest, setIsMedRest] = useState(false)
  const { currentRole } = useCurrentUser()
  const patientService = new PatientService()
  const { updatePatientData } = useUpdateDataAction(patientId)

  let options = ['enrollment', 'data_intake'].includes(currStage)
    ? ['Referral']
    : (currStage === 'med_baseline')
      ? isRecomMed
        ? ['Provider recommendation', 'Reset care plan', 'Referral', 'Medication choices']
        : ['Regenerate care plan', 'Provider recommendation', 'Reset care plan', 'Referral', 'Medication choices']
      : ['Reset care plan', 'Referral', 'Medication choices']

  options = [...options, 'Medication exclusion']
  options = options.sort()

  const handleConfirmClose = () => setConfirmOpen(false)
  const handleConfirmResetClose = () => setConfirmResetOpen(false)
  const closeMedChoicesDialog = () => setIsMedChoices(false)

  const confirmStatus = (param: boolean) => {
    if (param) {
      setConfirmOpen(false)
      setPlanLoading(true)
      handleClose()
      regenerateCarePlan()
    }
  }

  const handleMedRestriction = (param: boolean) => setIsMedRest(false)

  const confirmResetStatus = (param: boolean) => {
    if (param) {
      setConfirmResetOpen(false)
      setPlanLoading(true)
      resetCarePlan()
      handleClose()
    }
  }

  const printInstructions = (currInstObj: any) => {
    return Object.keys(currInstObj)?.length > 1 ? <List sx={{ padding: '0px 0px 5px 0.85rem' }}>
      {Object.keys(currInstObj)?.map((d: any) => {
        return <ListItem className={classes.puList} key={`inst-${d}`}>
          <Typography variant='body2' component="span" className={classes.evalBoxVal}>
            {currInstObj[d]}
          </Typography>
        </ListItem>
      })
      }
    </List>
      :
      <Typography variant='body2' component="span" className={classes.evalBoxVal}>
        {currInstObj[0] || ''}
      </Typography>
  }

  const getInstructions = (info = [] as any) => {
    return <Typography variant="body2" className={classes.desc}>
      {printInstructions(info?.instructions)}
    </Typography>
  }

  const confirmMedRestriction = () => {
    patientService.getMedRestrictions(patientId, currPrg).then((res: any) => {
      setIsMedRest(false)
      toast.warning(getInstructions(res.data), {
        position: 'bottom-center',
        autoClose: false,
      })
      updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
    }).catch((error) => {
      setIsMedRest(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const regenerateCarePlan = () => {
    patientService.regenerateCarePlan(patientId, careplanId).then((res: any) => {
      setPlanLoading(false)
      //toast.success('Careplan regenerate successfull.', Helper.bottom_center())
      updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
    }).catch((error) => {
      setPlanLoading(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const resetCarePlan = () => {
    patientService.resetCarePlan(patientId, careplanId).then((res: any) => {
      setPlanLoading(false)
      //toast.success('Careplan reset successfull.', Helper.bottom_center())
      updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
    }).catch((error) => {
      setPlanLoading(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDialogClose = () => {
    setAddmedicationCard(false)
  }

  const handlePlanAction = (e: any) => {
    let planAction = e.target.innerText
    setCurrentPlanAction(planAction)
    if (planAction === 'Provider recommendation')
      setAddmedicationCard(true)
    else if (planAction === 'Referral')
      onRefClick()
    else if (planAction === 'Medication choices')
      setIsMedChoices(true)
    else if (planAction === 'Medication exclusion') {
      setIsMedRest(true)
    } else if (planAction === 'Regenerate care plan')
      setConfirmOpen(true)
    else if (planAction === 'Reset care plan')
      setConfirmResetOpen(true)

    handleClose()
    return
  }

  return (<>
    {planloading && <CircularProgress className={classes.planLoading} />}
    {Helper.checkGroup(environment.okta.providerGroup, currentRole) && <>
      <IconButton size='small'
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertOutlinedIcon className={classes.MoreVertIconFontSize} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options?.map((option) => (
          <MenuItem
            key={option}
            disabled={['Regenerate care plan', 'Provider recommendation', 'Reset care plan', 'Referral', 'Medication exclusion'].includes(option) && prgStatus}
            value={option}
            selected={option === currentPlanAction}
            onClick={handlePlanAction}
            className={classes.desc}
            sx={{
              '&.Mui-disabled': {
                color: prgStatus ? 'grey !important' : '',
              }
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>}

    {addmedicationCard && (
      <ProviderRecommendationComponent
        open={addmedicationCard}
        careplanId={careplanId}
        currPrg={currPrg}
        patientId={patientId}
        onProvierSubmit={onProviderSubmit}
        handleClose={() => handleDialogClose()}
      />
    )}
    {confirmOpen &&
      <ConfirmationDialogComponent
        open={confirmOpen}
        content='Are you sure to regenerate care plan?'
        handleConfirmClose={handleConfirmClose}
        confirmStatus={confirmStatus} />
    }
    {confirmResetOpen &&
      <ConfirmationDialogComponent
        open={confirmResetOpen}
        content='Are you sure to reset the care plan?'
        handleConfirmClose={handleConfirmResetClose}
        confirmStatus={confirmResetStatus} />
    }
    {isMedChoices &&
      <MedChoicesComponent
        open={isMedChoices}
        handleClose={closeMedChoicesDialog}
        id={careplanId}
        title={title}
        category={'Treatments'}
      />
    }
    {isMedRest &&
      <ConfirmationDialogComponent
        open={isMedRest}
        content='Are you sure to execute medication restriction?'
        handleConfirmClose={handleMedRestriction}
        confirmStatus={confirmMedRestriction}
      />
    }
  </>
  )
}

export default withStyles(stylesheet)(LongMenu)
