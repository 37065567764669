import React, { useEffect, useState, memo, useRef, useCallback, useLayoutEffect, } from 'react'
import {
  Paper, TableContainer, Box, Table, TableBody, TableHead, TableRow, IconButton,
  TableCell, Typography, LinearProgress, Grid, Chip, TextField, Tooltip, CircularProgress,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { makeStyles, styled } from '@mui/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SyncIcon from '@mui/icons-material/Sync'
import GetAppIcon from '@mui/icons-material/GetApp'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import DescriptionIcon from '@mui/icons-material/Description'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import moment from 'moment'
import { useSelector } from 'react-redux'
import stylesheet from '../detail/detail-container.stylesheet'
import ConfirmationDialogComponent from '../detail/common/confirmation-dialog.component'
import { RootState, useAppThunkDispatch } from '../../../redux/store'
import { PatientService } from '../../../services/patient.service'
import Helper from '../../../utils/helper'
import ItableData from '../../../model/tableData'
import { setPatientsTable, setSelectedStage, setProgramFilter, setProgramChips } from '../../../redux/common-data-slice'
import ColorBubbleComponent from '../detail/common/color-bubble.component'
import AddPatientComponent from '../detail/info-header/add-patient.component'
import { checkCondition } from '../detail/common/condition-check'
import helper from '../../../utils/helper';
import ExtractPatientComponent from '../detail/info-header/extract-patient.component';

interface IProps {
  patientsTable: any
  // classes: ClassNameMap
}

const PatientDataTableComponent: React.FC<IProps> = ({
  patientsTable,
}) => {
  const tableEl = useRef<any>()
  const classes = makeStyles(stylesheet)();
  const patientService = new PatientService()
  const [loading, setLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [patientCount, setPatientCount] = useState(0)
  const [patientList, setPatientList] = useState([] as any)
  const [confirmResetOpen, setConfirmResetOpen] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deletePatient, setDeletePatient] = useState({} as any)
  const [currResetPatient, setCurrResetPatient] = useState({} as any)
  const dispatch = useAppThunkDispatch()
  const [distanceBottom, setDistanceBottom] = useState(0)
  const programFilter = useSelector((state: RootState) => state.commonData.programFilter)
  const programChips = useSelector((state: RootState) => state.commonData.programChips)
  const metaData = useSelector((state: RootState) => state.metaData.metadata)
  const [elationStatus, setElationStatus] = useState(null as any)
  const [tableData, setTableData] = useState<ItableData[]>([
    {
      header: 'Patient',
      width: '15%',
      align: 'left',
      sort: 'none',
      sortColumn: 'patient_name',
    },
    {
      header: 'MRN',
      width: '10%',
      align: 'left',
      sort: 'none',
      sortColumn: 'mrn',
    },
    {
      header: 'Program',
      width: '15%',
      align: 'left',
      sort: 'none',
      sortColumn: 'program_name',
    },
    {
      header: 'DOB',
      width: '10%',
      align: 'left',
      sort: 'none',
      sortColumn: 'date_of_birth',
    },
    {
      header: 'ID',
      width: '4%',
      align: 'right',
      sort: 'none',
      sortColumn: 'patient_id',
    },
    {
      header: 'Status',
      width: '5%',
      align: 'center',
      sort: 'none',
      sortColumn: 'is_inactive',
    },
    {
      header: 'Ref Status',
      width: '4%',
      align: 'left',
      sort: 'none',
      sortColumn: 'referral_status',
    },
    {
      header: 'Referral Updated On',
      width: '4%',
      align: 'center',
      sort: 'none',
      sortColumn: 'referral_last_modified_on',
    },
    {
      header: 'Clinic Patient ID',
      width: '9%',
      align: 'center',
      sort: 'none',
      sortColumn: 'clinic_patient_id',
    },
    {
      header: 'SBP',
      width: '5%',
      align: 'left',
      sort: 'none',
      sortColumn: 'sbp',
    },
    {
      header: 'DBP',
      width: '5%',
      align: 'left',
      sort: 'none',
      sortColumn: 'dbp',
    },
    {
      header: 'Last BP Recorded Date',
      width: '10%',
      align: 'left',
      sort: 'none',
      sortColumn: 'last_recorded_date',
    },
    {
      header: 'Action',
      width: '5%',
      align: 'left',
      sort: 'none',
      sortColumn: 'action',
    }
  ])
  const [openAddPatient, setOpenAddPatient] = useState(false)
  const [openExtract, setOpenExtract] = useState(false)
  const handleAddPatient = () => setOpenAddPatient(true)
  const handleExtract = () => setOpenExtract(true)
  const handleCloseExtract = () => setOpenExtract(false)

  const handleAddPatientClose = (res: boolean = false) => {
    if (res) {
      setLoading(true)
      setupData()
    }
    setOpenAddPatient(false)
  };

  const handleMeasurementSync = () => {
    setLoading(true)
    patientService.syncDeviceMeasurements().then((_res: any) => {
      setLoading(false)
      toast.success(
        'Device measurement sync successful',
        Helper.bottom_center()
      )
      getPatientList()
    }).catch((error) => {
      setLoading(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      setElationStatus(localStorage.getItem("importStatus"))
      if (localStorage.getItem("importStatus") === 'success' || localStorage.getItem("importStatus") === 'ongoing') {
        getImportStatus()
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getData()
  }, [patientsTable])

  const getData = () => {
    setLoading(true)
    setupData()
    if (patientsTable.search === '') {
      clearSearchText()
    }
    if (patientsTable.sort === '') {
      setTableData(
        tableData.map((el: any) => {
          return { ...el, sort: 'none' }
        })
      )
    }
  }

  useEffect(() => {
    const temp = tableData
      .filter((f) => f.sort !== 'none')
      .map((e) => e.sortColumn + '-' + e.sort)
      .join(',')
    if (temp) {
      dispatch(
        setPatientsTable({
          ...patientsTable,
          page: 1,
          sort: temp,
        })
      )
    }
  }, [tableData])

  useEffect(() => {
    if (patientList.length >= 25 && tableEl?.current?.scrollHeight === tableEl?.current?.clientHeight) {
      dispatch(
        setPatientsTable({
          ...patientsTable,
          limit: patientsTable.limit + 30,
        })
      )
    }
  }, [patientList])

  const getElationImport = () => {
    patientService.getElationImport().then((res: any) => {
      localStorage.setItem('importStatus', res?.data?.message)
      setElationStatus(res?.data?.message)
      if (res?.data?.message === 'success')
        toast.success('Elation import started', Helper.bottom_center())
    }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
  }

  const getImportStatus = async () => {
    patientService.elationimportStatus().then((res: any) => {
      localStorage.setItem('importStatus', res?.data?.message)
      setElationStatus(res?.data?.message)
    }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
  }

  const handleImportReport = () => {
    patientService.downloadeElationReport().then((res: any) => {
      if (res.data) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.setAttribute('download', `elation_import_status.csv`);
        document.body.appendChild(link);
        link.click();
      }
    }).catch((error: any) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
  }

  const debounce = (func: any, delay: any) => {
    let debounceTimer: any
    return function () {
      const context = func
      const args = arguments
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(() => func.apply(context, args), delay)
    }
  }
  const inputSearch = useRef<HTMLInputElement>(null)

  const setupData = () => {
    try {
      getPatientList()
      generateChips()
    } catch (error) {
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    }
  }

  const sortIcon = (d: any) => {
    if (d.sort === 'none') {
      return <UnfoldMoreIcon className={classes.fontSizeSmall} />
    } else if (d.sort === 'desc') {
      return <ArrowDropDownIcon className={classes.fontSizeSmall} />
    } else if (d.sort === 'asc') {
      return <ArrowDropUpIcon className={classes.fontSizeSmall} />
    }
  }

  const handleSort = (d: any) => {
    let nextSort = ''
    if (d.sort === 'none') {
      nextSort = 'asc'
    } else if (d.sort === 'asc') {
      nextSort = 'desc'
    } else if (d.sort === 'desc') {
      nextSort = 'none'
    }
    setTableData(
      tableData.map((el: any) =>
        el.header === d.header
          ? { ...el, sort: nextSort }
          : el
      )
    )
  }

  const getPatientList = () => {
    patientService
      .getPatientsWithFilters(patientsTable)
      .then(async (response: any) => {
        if (patientsTable.page > 1) {
          setPatientList([...patientList, ...response?.data?.patients_program_merged])
        } else {
          tableEl?.current?.scrollTo(0, 0)
          setPatientList(response?.data?.patients_program_merged)
        }
        setLoading(false)
        setTotalRecords(response?.data?.total_records)
        setPatientCount(response?.data?.patients_count)
      })
      .catch((error: any) => {
        setError(error)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
        setLoading(false)
        setTotalRecords(0)
        setPatientCount(0)
      })
  }

  const setError = (error: any) => {
    toast.error(Helper.getErrorText(error), {
      position: 'bottom-center',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    })
  }

  const generateChips = () => {
    const {
      page,
      limit,
      sort,
      ...chipsd
    }: { page: number; limit: number; sort: string; chipsd: string[]; } = {
      ...patientsTable,
    }

    const getDisplayName = (key: any, currData: any) => {
      return (key === 'hospitals')
        ? currData?.split(',')
          ?.map((i: any) => (metaData && metaData?.HOSPITALS !== undefined)
            ? (metaData?.HOSPITALS)[i]
            : helper.stageName(i)
          )
          ?.join(', ')
        : ['stage', 'status'].includes(key)
          ? currData?.split(',')?.map((i: any) => helper.stageName(i))?.join(', ')
          : currData
    }

    dispatch(setProgramChips(
      Object.entries(chipsd)?.filter((f: any) => f[1] !== '')?.map((m: any) => {
        return {
          key: m[0],
          value: m[1],
          display_name: getDisplayName(m[0], m[1])
        }
      })
    ))
  }
  const getPrgDisplayName = (a: any) => {
    const currPrg = programChips?.find((item: any) => item['key'] === 'selectedPrg')?.value || []
    return currPrg.find((i: any) => i.program === a)?.display_name || a
  }

  const handlePrgCheck = (name: string, index: any, parent: any, subParent: any) => {
    let currParent = programFilter['programs']?.filter((e: any) => e.display_name === parent)[0]
    let currParentIndex = programFilter['programs']?.findIndex((e: any) => e.display_name === parent)

    if (currParentIndex !== -1) {
      let updatedItems = [...currParent['items']]?.map((m: any) => {
        if (m.display_name === subParent) {
          let currSubItem = m?.items.map((i: any) => {
            if (i?.name === name) {
              return { ...i, checked: false }
            } else {
              return { ...i }
            }
          })
          return { ...m, items: currSubItem }
        }
        else
          return { ...m }
      })

      let allSelectedPrg
      let selectedPrgList = []

      const selectedPrg = programChips?.find((item: any) => item['key'] === 'selectedPrg')?.value || []
      selectedPrgList = [...selectedPrg]?.filter((item: any) => item?.index !== index)
      allSelectedPrg = [...selectedPrgList].map((p: any) => p.program).join(',')

      let updatedFilterCounts = { ...programFilter }
      let updatedPrgram = [...programFilter['programs']]
      updatedPrgram[currParentIndex] = { ...currParent, 'items': updatedItems }
      updatedFilterCounts['programs'] = updatedPrgram

      dispatch(setPatientsTable({
        ...patientsTable,
        page: 1,
        limit: 25,
        programs: allSelectedPrg,
        selectedPrg: selectedPrgList,
      }))
      dispatch(setProgramFilter(updatedFilterCounts))
    }
  }

  const handleDelete = (key: string, isProgram = false) => {
    if (isProgram) {
      let currPrgList = programChips?.find((item: any) => item['key'] === 'programs')?.value || []
      currPrgList = currPrgList.split(',')?.filter((item: any) => item !== key).join(',')

      let currPrg = programChips?.find((item: any) => item['key'] === 'selectedPrg')?.value || []
      currPrg = currPrg.find((i: any) => i.program === key)

      handlePrgCheck(key, currPrg?.index, currPrg?.parent, currPrg?.subParent)
    } else
      dispatch(
        setPatientsTable({ ...patientsTable, page: 1, limit: 25, [key]: '' })
      )
    if (key === 'stage') {
      dispatch(setSelectedStage(''))
    }
    if (key === 'search') {
      clearSearchText()
    }
    if (key === 'hospitals')
      localStorage.setItem("atman-hospitals", '')
  }

  const fetchNextSetData = () => {
    if (loading === false && (calculatePCount() !== patientCount)) {
      dispatch(
        setPatientsTable({ ...patientsTable, page: patientsTable.page + 1 })
      )
    }
  }

  const calculatePCount = useCallback(() => {
    return Array.from(new Set(patientList.map((p: any) => p.patient_id))).length
  }, [patientList])

  const scrollListener = useCallback(() => {
    let bottom = tableEl?.current?.scrollHeight - tableEl?.current?.clientHeight
    if (!distanceBottom) {
      // calculate distanceBottom that works
      setDistanceBottom(Math.round((bottom / 100) * 20))
    }

    const len = patientList.map((m: { patient_id: any }) => m.patient_id).length
    if (
      !loading &&
      tableEl?.current?.scrollTop > bottom - distanceBottom &&
      len < totalRecords
    ) {
      fetchNextSetData()
    }
  }, [distanceBottom, loading, totalRecords])

  const onCloseClick = () => {
    clearSearchText()
    dispatch(
      setPatientsTable({
        ...patientsTable,
        search: '',
      })
    )
  }

  const clearSearchText = () => {
    if (inputSearch?.current !== null) {
      (
        inputSearch.current.getElementsByClassName(
          'MuiInputBase-input'
        )[0] as HTMLInputElement
      ).value = ''
    }
  }

  useLayoutEffect(() => {
    const tableRef = tableEl?.current
    tableRef?.addEventListener('scroll', scrollListener)
    return () => {
      tableRef?.removeEventListener('scroll', scrollListener)
    }
  }, [scrollListener])

  const confirmResetStatus = () => {
    currResetPatient?.patient_id && patientService.resetPatient(currResetPatient?.patient_id, { programs: currResetPatient?.program_name })
      .then((_res: any) => {
        setConfirmResetOpen(false)
        setCurrResetPatient({})
        toast.success(
          `Patient ${currResetPatient.first_name} reset successfully`,
          Helper.bottom_center()
        )
      }).catch((error) => {
        setConfirmResetOpen(false)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      })
  }

  const handleConfirmReset = () => setConfirmResetOpen(false)

  const onExport = (patientId: any) => {
    patientService.exportPatient(patientId).then((res: any) => {
      const exportUrl = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = exportUrl;
      link.setAttribute('download', `${patientId}.json`);
      document.body.appendChild(link);
      link.click();
    }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
  }

  const handleConfirmDelete = () => {
    setDeletePatient({})
    setConfirmDelete(false)
  }

  const handleDeletePatient = (patientInfo: any) => {
    setDeletePatient(patientInfo)
    setConfirmDelete(true)
  }

  const confirmDeletePatient = () => {
    deletePatient?.patient_id !== '' && patientService.deletePatient(deletePatient?.patient_id).then((res: any) => {
      setConfirmDelete(false)
      toast.success(`Patient ${deletePatient?.patient_name} deleted successfully.`, Helper.bottom_center())
      window.location.reload()
    }).catch((error) => {
      setConfirmDelete(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    }).finally(() => setDeletePatient(''))
  }

  return (<>
    {loading && <LinearProgress />}
    <Box p={'1rem 0.5rem'}>
      {elationStatus === 'ongoing' &&
        <Typography variant="subtitle2" pl={1}>
          Elation import in progress...<CircularProgress size={15} />
        </Typography>
      }
      <Box p={1}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle2">
              {`Showing ${calculatePCount()} out of ${patientCount} patients`}
            </Typography>
          </Grid>
          <Grid item>
            {programChips.map((c: any, j: number) => (
              <React.Fragment key={`prg-chip-${j}`}>
                {c.key === 'programs' || c.key === 'selectedPrg'
                  ? <>
                    {c.key === 'programs' && c.value?.split(',')?.map((a: any) =>
                      <Chip
                        key={j}
                        sx={{
                          height: '24px ',
                          margin: '2.4px',
                          backgroundColor: '#fff',
                          marginTop: '0.5rem'
                        }}
                        label={<small>{getPrgDisplayName(a)} </small>}
                        variant="outlined"
                        onDelete={() => handleDelete(a, true)}
                      />
                    )}
                  </>
                  : <Chip
                    key={j}
                    sx={{
                      height: '24px ',
                      margin: '2.4px',
                      backgroundColor: '#fff',
                      marginTop: '0.5rem'
                    }}
                    label={<small>{c?.display_name || c?.value}</small>}
                    variant="outlined"
                    onDelete={() => handleDelete(c.key, false)}
                  />
                }
              </React.Fragment>
            ))}
            <TextField
              size="small"
              id="search-input"
              variant="outlined"
              disabled={loading}
              ref={inputSearch}
              className={classes.searchFieldBg}
              onChange={debounce((e: any) => {
                dispatch(
                  setPatientsTable({
                    ...patientsTable,
                    page: 1,
                    search: e.target.value,
                  })
                )
              }, 1000)}
              placeholder="search"
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton className={classes.sizeSmall} disabled={loading} onClick={() => onCloseClick()}>
                      {patientsTable.search ? (
                        <CloseIcon />
                      ) : (
                        <SearchIcon />
                      )}
                    </IconButton>
                  </>
                ),
                inputProps: { style: { padding: '0 0 0 5px' } },
                classes: {
                  adornedEnd: classes.adornedEnd
                }
              }}
            />
            <Tooltip title='Add patient' placement="bottom">
              <span>
                <IconButton
                  size="small"
                  disabled={loading}
                  sx={{ padding: '2px 4px 0px 12px' }}
                  onClick={handleAddPatient}
                >
                  <PersonAddAlt1Icon sx={{ fontSize: '1.2rem' }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Extract' placement="bottom">
              <span>
                <IconButton
                  size="small"
                  disabled={loading}
                  sx={{ marginTop: '5px', padding: '0px 2px' }}
                  onClick={handleExtract}>
                  <GetAppIcon sx={{ fontSize: '1.2rem' }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Sync device measurements' placement="bottom">
              <span>
                <IconButton
                  disabled={loading}
                  onClick={handleMeasurementSync}
                  sx={{ marginTop: '3px', padding: '0px 6px 0px 6px' }}
                >
                  <SyncIcon sx={{ fontSize: '1.1rem' }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Elation import' placement="bottom">
              <span>
                <IconButton
                  size="small"
                  disabled={loading || elationStatus === 'null' || elationStatus === 'ongoing'}
                  sx={{ marginTop: '5px', padding: '0px 2px', }}
                  onClick={getElationImport}
                >
                  <GroupAddIcon sx={{
                    fontSize: '1.2rem',
                    fill: (!loading && elationStatus === 'completed') ? 'green' : 'grey'
                  }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Elation import status' placement="bottom">
              <span>
                <IconButton
                  size="small"
                  disabled={loading || elationStatus !== 'completed'}
                  sx={{ marginTop: '6px' }}
                  onClick={handleImportReport}
                >
                  <DescriptionIcon sx={{
                    fontSize: '1.1rem',
                    fill: (!loading && elationStatus === 'completed') ? 'green' : 'grey'
                  }} />
                </IconButton>
              </span>
            </Tooltip>

            {openAddPatient && (
              <AddPatientComponent
                openAddPatient={openAddPatient}
                onAddPatientClose={(res: boolean = false) => handleAddPatientClose(res)}
              />
            )}
            {openExtract && (
              <ExtractPatientComponent
                openExtract={openExtract}
                handleCloseExtract={handleCloseExtract}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: "100vh" }} ref={tableEl}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableData.map((t: any, i: number) => (
                <TableCell
                  key={`patient-${i}`}
                  className={
                    [t.sort === 'none'
                      ? classes.tablePaddingPListHeader
                      : classes.tablePaddingPListSelected, classes.patientListHeadersBorder].join(' ')
                  }
                  sx={{ padding: '4px !important' }}
                  color="textSecondary"
                  width={t.width}
                  align={t.align}
                >
                  <Box pl={1} display="flex" justifyContent="space-between">
                    {t.header}
                    {!['Patient', 'Action'].includes(t.header) &&
                      <IconButton sx={{ padding: '3px 0px !important' }} onClick={() => handleSort(t)}>
                        {sortIcon(t)}
                      </IconButton>
                    }
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody id="scrollableDivPL">
            {patientList?.map((row: any, i: number) => (
              <TableRow key={`pl-demo-${i}`} sx={{
                backgroundColor:
                  confirmDelete && deletePatient?.patient_id === row.patient_id
                    ? '#ee18041c'
                    : confirmResetOpen && currResetPatient?.patient_id === row.patient_id
                      ? '#e1e1e1'
                      : row.color === 'W' ? '#fff' : '#F7F8FA'
              }}>
                <TableCell className={classes.tablePaddingPList}>
                  <Typography variant="subtitle2">
                    <Link to={`/patients/${row.patient_id}/details`} className={classes.patientsName}>
                      {`${row.patient_name}`}
                    </Link>
                    <ColorBubbleComponent selectedProgramList={row?.program_name} />
                  </Typography>
                </TableCell>
                <TableCell
                  className={[classes.tablePaddingPList, classes.overflowWrapAnywhere].join(' ')}
                  align="justify"
                >
                  <Typography variant="body2">
                    {checkCondition(row.mrn !== null, row.mrn?.join(', '), '')}
                  </Typography>
                </TableCell>
                <TableCell className={[classes.tablePaddingPList, classes.overflowWrapAnywhere].join(' ')}>
                  <Typography variant="body2">{row.program_display_name.join(', ')}</Typography>
                </TableCell>
                <TableCell className={[classes.tablePaddingPList, classes.overflowWrapAnywhere].join(' ')}>
                  <Typography variant="body2" >
                    {row.date_of_birth
                      ? moment(row.date_of_birth).format('MMM DD YYYY')
                      : ''}
                  </Typography>
                </TableCell>
                <TableCell
                  className={classes.tablePaddingPList}
                  align="justify"
                >
                  <Typography variant="body2">
                    {row.patient_id}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tablePaddingPList}>
                  {row?.is_inactive &&
                    <PersonRemoveIcon
                      titleAccess={'Patient inactive'}
                      sx={{
                        fontSize: '18px',
                        fill: 'grey',
                        paddingLeft: '1.2rem'
                      }} />
                  }
                </TableCell>
                <TableCell className={[classes.tablePaddingPList, classes.alignTextCenter].join(' ')} >
                  {checkCondition(row.referral_status,
                    checkCondition(row.referral_status == "ESCALATED",
                      <IconButton className={classes.iconBaseBorder}>
                        <PriorityHighIcon
                          className={[classes.notVal2, classes.compactMenuIcon].join(' ')}
                        />
                      </IconButton>,
                      checkCondition(row.referral_status == "RESOLVED",
                        <IconButton className={classes.iconBaseBorder}>
                          <CheckCircleOutlineIcon
                            className={[classes.notVal, classes.compactMenuIcon].join(' ')}
                          />
                        </IconButton>,
                        checkCondition(row.referral_status == "OPEN",
                          <IconButton className={classes.iconBaseBorder}>
                            <PriorityHighIcon
                              className={[classes.notVal, classes.compactMenuIcon].join(' ')}
                            />
                          </IconButton>, ''))))}
                </TableCell>
                <TableCell className={[classes.tablePaddingPList, classes.overflowWrapAnywhere].join(' ')}>
                  {(row.referral_status && row.referral_status != "NOT_AVAILABLE") ? row.referral_last_modified_on ? moment.unix(row.referral_last_modified_on).format('MMM DD YYYY hh:mm a') : '' : ''
                  }
                </TableCell>
                <TableCell className={[classes.tablePaddingPList, classes.overflowWrapAnywhere].join(' ')}>
                  <Typography variant="body2" >
                    {checkCondition(row.clinic_patient_id, row.clinic_patient_id, '')}
                  </Typography>
                </TableCell>
                <TableCell className={[classes.tablePaddingPList, classes.overflowWrapAnywhere].join(' ')}>
                  <Typography variant="body2">{row.sbp?.value || ''}</Typography>
                </TableCell>
                <TableCell className={[classes.tablePaddingPList, classes.overflowWrapAnywhere].join(' ')}>
                  <Typography variant="body2">{row.dbp?.value || ''}</Typography>
                </TableCell>
                <TableCell className={[classes.tablePaddingPList, classes.overflowWrapAnywhere].join(' ')}>
                  <Typography variant="body2">{row.last_recorded_date ? moment.unix(row.last_recorded_date).format('MMM DD YYYY hh:mm a') : ''}</Typography>
                </TableCell>
                <TableCell className={classes.tablePaddingPList}>
                  <Grid container justifyContent="flex-start" direction="row">
                    <Grid item xs={4}>
                      <Tooltip title='Export patient' placement="bottom">
                        <IconButton
                          aria-label='Export patient'
                          color='inherit'
                          onClick={() => onExport(row.patient_id)}
                          sx={{ fontSize: '14px', padding: '2px 2px 0px 0px' }}
                        >
                          <GetAppIcon className={classes.resetPatientIcon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                      <Tooltip title='Reset patient' placement="bottom">
                        <IconButton
                          aria-label='Reset Patient'
                          disabled={!row.is_test_patient}
                          color='inherit'
                          size='small'
                          sx={{ padding: '0px 2px 0px 0px' }}
                          onClick={() => {
                            setConfirmResetOpen(true)
                            setCurrResetPatient({
                              'patient_id': row.patient_id,
                              'first_name': row.patient_name,
                              'program_name': row?.program_name
                            })
                          }}
                        >
                          <RefreshIcon
                            className={classes.resetPatientIcon}
                            sx={{ color: !row.is_test_patient ? 'gray !important' : '' }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4} >
                      <Tooltip title='Delete patient' placement="bottom">
                        <IconButton
                          aria-label='Delete patient'
                          color='inherit'
                          onClick={() => handleDeletePatient({
                            patient_id: row.patient_id,
                            patient_name: row.patient_name
                          })}
                          sx={{ padding: '0px 2px 0px 0px' }}
                          size='small'
                        >
                          <DeleteOutlinedIcon className={classes.resetPatientIcon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
            {loading === false && patientList.length === 0 && (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={tableData.length}
                >
                  <b>Data not available!</b>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableHead>
            {loading && (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={tableData.length}
                >
                  <b>Loading...</b>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
        </Table>
      </TableContainer>
      {confirmResetOpen &&
        <ConfirmationDialogComponent
          open={confirmResetOpen}
          content={`Are you sure you want to reset the patient ${currResetPatient?.first_name || ''} details?`}
          handleConfirmClose={handleConfirmReset}
          confirmStatus={confirmResetStatus}
        />
      }
      {confirmDelete &&
        <ConfirmationDialogComponent
          open={confirmDelete}
          content={`Are you sure you want to delete the patient ${deletePatient?.patient_name || ''}?`}
          handleConfirmClose={handleConfirmDelete}
          confirmStatus={confirmDeletePatient}
        />
      }
    </Box >
  </>
  )
}

export default memo((PatientDataTableComponent))