import React, { useEffect } from 'react';
import axios from 'axios';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppThunkDispatch } from '../redux/store';
import { resetEncryptedToken, setEncryptedToken } from '../redux/common-data-slice';
import { PatientService } from '../services/patient.service';
import { environment } from '../environments/environment';

interface Idata {
	oktaAuth: any,
	history: any,
	location: any,
	match: any,
	children: any,
	requestInterceptorHandler: any,
	requestInterceptorErrorHandler: any,
	responseInterceptorSuccessHandler: any,
	responseInterceptorErrorHandler: any,
}

let requestInterceptor: any = null;
let responseInterceptor: any = null;

const UndecoratedSetupAxios: React.FC<any> = ({ oktaAuth, history, location, match,
	children, requestInterceptorHandler = (config: any) => config,
	requestInterceptorErrorHandler = (error: any) => Promise.reject(error),
	responseInterceptorSuccessHandler = (response: any) => response,
	responseInterceptorErrorHandler = (error: any) => Promise.reject(error) }) => {
	const dispatch = useAppThunkDispatch();
	const encryptedToken = useSelector((state: RootState) => state.commonData.encryptedToken.token)
	const patientService = new PatientService()

	useEffect(() => {
		requestInterceptor = axios.interceptors.request.use(
			requestInterceptorSuccessHandler,
			requestInterceptorErrorHandlerFun
		);
		responseInterceptor = axios.interceptors.response.use(
			responseInterceptorSuccessHandlerFun,
			responseInterceptorErrorHandlerFun
		);
		return () => {
			axios.interceptors.request.eject(requestInterceptor);
			axios.interceptors.response.eject(responseInterceptor);
		}
	}, [encryptedToken])

	const tokenVerificationAPI = async (url: string) => {
		try {
			const res = await patientService.tokenVerification()
			dispatch(setEncryptedToken({ token: res.data.token, status: 'ready' }))
			return res.data.token
		} catch (error) {
			console.error(error)
		}
	}

	const requestInterceptorSuccessHandler = async (config: any) => {
		const token = await oktaAuth?.getAccessToken();
		// If not able to retrieve a token, send the user back to login
		if (typeof token === 'undefined' && oktaAuth) {
			await oktaAuth.revokeAccessToken()
			oktaAuth.signOut()
			dispatch(resetEncryptedToken())
			return config;
		}
		const newConfig = requestInterceptorHandler(config);

		let tokenFilter = environment?.okta?.isEnabled
			? newConfig.url.endsWith('/token-verification') ? token : encryptedToken
			: 'eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIn0..7-LOy9rUOPP06X3w0rIkyQ.WXdHCygyeEn5iC2Y8tSwa33m199HBIoqS_XIn0EAizo6iWZq95I8p5Xx5Bq309hthEmdRmQC1dkoPBesN6l2XSeqzgNfHimqA1UhsNf0H_9oMRvYvDKJACm0mHylbbq20YYCFBzv1RB88oAxvxANsh3t41kigmuvGshLarDwFbXK96WfrE_t18ogVKl15fCy9DIJb4DZ6KfhmjxJhZ3uTCPpa_rxmLjKWZGjpRVsw2PUed-RDcsgyroCCNehY0qc2wSPZjCUCMZ7N1OCW30Ek5tv_Y8v9_IH7FzG70ILnXVuF3QPvWCy8J6uvYvdQ_GM3sFmQDWypWK_euWxr43oztuEwtgZTIXEZEq4XLlNJwZ1qcDVHsSGHZmx6txBPyoCNCOksk0lVdTwGN9r3GfmWGPZaionGp8Ay4Pd8sT4qStuGu74bq6iL43IguqU1yYEKMuB-GZznthpbUT4JRAwXsooJdtQoMM4vdhMANGW680Ox-kgcAQsL8FdZBygA83sITo8E1twYMAH3P05U-RqhpGs0CRDpoq_tasz6pcxa48sgBenyCqgWGqVDNE5EN6Ma7RWPAMs50IMSEO2TOS0BIxqHgcY7-3dOjyLyNTF7dBNU9Ty_AF_dDpL5Uir_d3J7k0h5VgT8PcqOpML7JEILZmJI8Bi8Z2_XioXS7FDOVjNhJRTnkdH9igbhO-Q4yoxr2P04_wJXgXRxmXyGM_Kjt27sMNE5jpL6EaiSR1isoSqtolakFxfr6cJQfhHhOwSSjxqoR1d4sjq8Z_AES6e8zrFFgehQ9MpdJP7NrrMRtOe6Eg1gzKcfPnocZl_nueCZp3faDEzL9lFpQ0SOUwFQiylV4CuEwf5Ec9vmW2uvK_DC_iEEUDoVOXNr_0pt_Y0N6V569ZKe-mc29bjrBCh8P5SpSEtCY_w4K-dJ1FISLvnIOdvr0qJjsrvXR0BJM5E_8pz8ignWv0YMCJtjRC8m0Pmjdw9eSIYYiS_vzSdRJT4UJnFb1vkHSTt_OhTCXpzaW122orVjkniax-2oQYM3U5B4geSQTiXDOgbkasfNRU2Fcyul552pQzxVmQQDIELCHPhfPnVt-Wpoyw8ZwBgIrajnhp-7LgCiNaV2A2ccnXo2uMTcLgG80eYlzhtLX1X4vol5X2l6U7ojHnd3IFap0d9KpTJ4KWqTEnUQ-SOUYk0U7ep1qkgvMlAZjuuSLgJ.QVLo42D2LC8qXWXI-BI58Q'

		if (tokenFilter === '')
			tokenFilter = await tokenVerificationAPI(config.url)

		// Return the config with the token appended to the Authorization Header
		let newHeaders = {}
		newConfig.headers.Accept = 'application/json';
		if (newConfig?.method === 'get') {
			newHeaders = {
				...newConfig,
				headers: {
					Authorization: `Bearer ${tokenFilter}`, ...(newConfig?.headers || {})
				},
			};
		} else if (newConfig?.method === 'post' && newConfig.url.endsWith('ccd/uploads')) {
			newHeaders = {
				...newConfig,
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${tokenFilter}`, ...(newConfig?.headers || {})
				},
			};
		} else {
			newHeaders = {
				...newConfig,
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${tokenFilter}`, ...(newConfig?.headers || {})
				},
			};
		}
		return newHeaders;
	};

	const requestInterceptorErrorHandlerFun = (error: any) =>
		requestInterceptorErrorHandler(error);

	const responseInterceptorSuccessHandlerFun = (response: any) =>
		responseInterceptorSuccessHandler(response);

	const responseInterceptorErrorHandlerFun = async (error: any) => {
		if (error?.response?.status === 401) {
			await oktaAuth.revokeAccessToken()
			dispatch(resetEncryptedToken())
			oktaAuth.signOut()
		}
		return responseInterceptorErrorHandler(error);
	}
	return children;

}
UndecoratedSetupAxios.displayName = "SetupAxios"
export default withRouter(withOktaAuth(UndecoratedSetupAxios));