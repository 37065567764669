import React, { useEffect, useState } from 'react'
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Box } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import ReactJson from 'react-json-view';
import Draggable from "react-draggable"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import stylesheet from '../detail-container.stylesheet'
import EmptyCardComponent from './empty-card.component';

interface IProps {
  openFhirJson: boolean
  handleFhirJsonClose: any
  medTitle: any
  medMoreDetails: any
  classes: ClassNameMap
}

const MedDetailsComponent: React.FC<IProps> = ({
  openFhirJson,
  handleFhirJsonClose,
  medTitle,
  medMoreDetails,
  classes
}) => {

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(function () {
      setLoading(false)
    }, 1000);
  }, [])
  return (
    <>
      <Draggable defaultPosition={{ x: 80, y: 20 }} handle='.med-details-dialog'>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openFhirJson}
          onClose={handleFhirJsonClose}
          hideBackdrop
          disableScrollLock
          sx={{
            position: "absolute",
            left: '11rem',
          }}
        >
          <DialogTitle className={classes.contextualHelpHeader} classes={{ root: 'med-details-dialog' }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography color="textSecondary">
                  <small>{`Med Details / Treatments`}</small>
                </Typography>
                <Typography id="modal-modal-title" variant="body1" className={classes.subtitle2}>
                  <strong>{medTitle}</strong>
                </Typography>
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={() => handleFhirJsonClose()}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          {loading && <LinearProgress />}
          <DialogContent className={classes.contextualHelpContext} sx={{ maxHeight: '33rem', overflowY: 'scroll' }}>
            {!loading &&
              <>
                {Object.keys(medMoreDetails)?.length > 0 ? (
                  <ReactJson
                    style={{
                      padding: '1rem',
                    }}
                    src={medMoreDetails}
                    name={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                ) : (
                  <Box p={2}><EmptyCardComponent /></Box>
                )}
              </>
            }
          </DialogContent>
        </Dialog>
      </Draggable>
    </>
  );
}

export default withStyles(stylesheet)(MedDetailsComponent)