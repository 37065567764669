import React, { useCallback, useEffect, useState } from 'react'
import {
  Grid, Box, Typography, LinearProgress, IconButton, Tooltip, MenuItem, Menu, Divider
} from '@mui/material'
import { withStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiChip from '@mui/material/Chip'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import BallotIcon from '@mui/icons-material/Ballot';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { ClassNameMap } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import stylesheet from '../detail-container.stylesheet'
import { RootState, useAppThunkDispatch } from '../../../../redux/store'
import Helper from '../../../../utils/helper'
import { setSelectedProgram } from '../../../../redux/common-data-slice';
import { PatientService } from '../../../../services/patient.service'
import { checkCondition } from '../common/condition-check'
import { useDispatchAPIs } from '../../../../utils/use-dispatchApis-hook'
import ConfirmationDialogComponent from '../common/confirmation-dialog.component'
import AddLifestyleDialogComponent from './add-lifestyle.component'
import AddMonitorComponent from './add-monitor.component'
import PendingItemsDialogComponent from './pending-items.component'
import OverrideItemsComponent from './override-items.component';
import { useUpdateDataAction } from '../../../../utils/update-details-hook';

interface IProps {
  handleCloseOutNote: any
  classes: ClassNameMap
}

const SubHeaderComponent: React.FC<any> = ({ handleCloseOutNote, classes }) => {
  const dispatch = useAppThunkDispatch()
  const [data, setData] = useState([] as any)
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [clickedProgram, setClickedProgram] = useState('' as any)
  const [isMonitor, setIsMonitor] = useState(false)
  const [isLifestyle, setIsLifestyle] = useState(false)
  const [monitorData, setMonitorData] = useState([] as any)
  const [lifestyleData, setLifestyleData] = useState([] as any)
  const [isActionItem, setIsActionItem] = useState(false)
  const [prgStatus, setPrgStatus] = useState(null as any)
  const [isPendingItem, setIsPendingItem] = useState(false)
  const patientService = new PatientService()
  const [removePrgEnrollment, setRemovePrgEnrollment] = useState(false)
  const patientDetail: any = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)
  const patientDetailStatus: any = useSelector((state: RootState) => state.patientDetail.status)
  const selectedProgram = useSelector((state: RootState) => state.commonData.selectedProgram)
  const multiProgramConfirm = useSelector((state: RootState) => state.multiProgramConfirm)
  const stageChange = useSelector((state: RootState) => state.stageChange.loading)

  const Chip = withStyles((theme) => ({
    root: {
      padding: theme.spacing(1),
      border: 'none',
      borderRadius: '4px',
    },
    labelSmall: {
      width: '100%',
    },
  }))(MuiChip)

  const onChipClick = (item: string) => {
    if (selectedProgram.includes(item)) {
      if (selectedProgram.length !== 1) {
        dispatch(
          setSelectedProgram(selectedProgram.filter((f: any) => f !== item))
        )
      }
    } else {
      dispatch(setSelectedProgram([...selectedProgram, item]))
    }
  }

  const handleClose = () => setAnchorEl(null)

  useEffect(() => {
    if (isMonitor) {
      patientService.getMonitorList().then((response: any) => {
        setMonitorData(response.data)
      }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
    }
  }, [isMonitor])

  useEffect(() => {
    if (isLifestyle) {
      patientService.getLifestylesList().then((response: any) => {
        setLifestyleData(response.data)
      }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
    }
  }, [isLifestyle])

  const handlePrgStatusConfirmClose = () => setPrgStatus(null)

  const handlePrgStatus = (status: any) => {
    setAnchorEl(null)
    setPrgStatus(status)
  }

  const confirmPrgStatus = () => {
    const status = (prgStatus) || null
    if (patientDetail.program_enrollments
      ?.find((f: { program: any }) => f.program === clickedProgram)
      ?.status !== status.toLocaleLowerCase()
    ) {
      setLoading(true)
      patientService.changeProgramStatus(patientDetail.id, clickedProgram, status).then(() => {
        setLoading(false)
        setPrgStatus(null)
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      }).catch((error: any) => {
        setLoading(false)
        setPrgStatus(null)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      })
    }
    //else
    //setPrgStatus(null)
  }

  const handleMonitorSubmit = (currMonitor: any) => {
    setIsMonitor(false)

    const enrollmentId = patientDetail?.program_enrollments.filter((m: { program: any }) =>
      m.program === clickedProgram)?.[0]?.enrollment_id || null
    if (enrollmentId) {
      patientService.createMonitor(enrollmentId, currMonitor).then((response: any) => {
        if (response.status === 200) {
          updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: false })
        }
      }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
    }
  }

  const handleLifestyleSubmit = (currLifestyle: any) => {
    setIsLifestyle(false)

    const enrollmentId = patientDetail?.program_enrollments.filter((m: { program: any }) =>
      m.program === clickedProgram)?.[0]?.enrollment_id || null
    if (enrollmentId) {
      patientService.createLifestyle(enrollmentId, currLifestyle).then((response: any) => {
        if (response.status === 200) {
          updatePatientData({ isTreatments: true, })
        }
      }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
    }
  }

  const handleMonitorDialogClose = () => setIsMonitor(false)
  const handleLifestyleDialogClose = () => setIsLifestyle(false)
  const handleActionDialogClose = () => setIsActionItem(false)
  const handlePendingDialogClose = () => setIsPendingItem(false)

  const handleActionItem = () => {
    setIsActionItem(true)
    handleClose()
  }

  const handleOverrideItem = () => {
    setIsPendingItem(true)
    handleClose()
  }

  const handleCloseNote = () => {
    handleClose()
    handleCloseOutNote(clickedProgram)
  }

  const handleAddLifestyle = () => {
    setIsLifestyle(true)
    handleClose()
  }

  const handleAddMonitor = () => {
    setIsMonitor(true)
    handleClose()
  }

  const handleRemoveEnrolment = () => {
    setRemovePrgEnrollment(true)
    handleClose()
  }

  const renderProgramMenu = (title: any, status: any, handleClick: any) => {
    return <MenuItem
      onClick={status === 'inactive' ? undefined : handleClick}
      className={status === 'inactive' ? classes.subTitleDisabled : classes.subTitle}
    >
      {title}
    </MenuItem>
  }

  const getPrgActionList = () => {
    return [
      { title: 'Add lifestyle plan', callback: handleAddLifestyle },
      { title: 'Add monitor', callback: handleAddMonitor },
      { title: 'Evaluate toxicities', callback: handleToxicities },
      { title: 'Pending items', callback: handleActionItem },
      { title: 'Override measurements', callback: handleOverrideItem },
      { title: 'Start enrollment', callback: setProgramStage },
      { title: 'Generate closeout note', callback: handleCloseNote },
    ]
  }

  const setProgramStage = async () => {
    setAnchorEl(null)
    try {
      setLoading(true)
      const temp = await patientService.changeProgramStage(
        patientDetail.id,
        clickedProgram
      )
      if (temp && temp?.data)
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setClickedProgram(event.currentTarget.getAttribute('data-program'))
    setAnchorEl(event.currentTarget)
  }

  const getLable = (e: any) => {
    return (
      <Grid container>
        <Box>
          <Typography
            variant="body2"
            className={classes.subHeaderProgramName}
            color={e.status === 'inactive' ? '#616161 !important' : 'inherit'}
          >
            {e.status === 'inactive'
              ? <>{`${e.program_display_name} - `}</>
              : <strong>{e.program_display_name} - </strong>
            }
            <span>{stageChange === false ? Helper.stageName(e.current_stage) : '...'}</span>
          </Typography>
        </Box>
        <Box pl={3} className={classes.subHeaderStageBox}>
          <Typography
            className={classes.subHeaderProgramName}
            variant="body2"
            color={e.status === 'inactive' ? '#616161 !important' : 'inherit'}
          >
            {e.status === 'inactive'
              ? <>{multiProgramConfirm.find((m) => m.program === e.program)?.pending || '0'}</>
              : <strong>
                {multiProgramConfirm.find((m) => m.program === e.program)?.pending || '0'}
              </strong>
            }
          </Typography>
        </Box>
      </Grid>
    )
  }

  useEffect(() => {
    const stage = patientDetail?.program_enrollments?.map((e: any) => {
      return { ...e }
    })
    setData(stage)
  }, [patientDetail])

  const createCase = (program: string, enrollId: string) => {
    setLoading(true)
    patientService
      .createCase(patientDetail.id, program)
      .then((response: any) => {
        setLoading(false)
        if (response?.data?.success?.case_number) {
          updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
        } else if (response?.data?.cases[0].ref === 'DUPLICATE_PATIENT_CASE') {
          toast.info(
            `Case already exists on Salesforce, case number ${response.data.cases[0].case_number}`,
            {
              position: 'bottom-center',
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
            }
          )
        }
        patientService.deepLink(patientDetail.id, enrollId).then((res: any) => {
          setData(
            data.map((el: any) =>
              el.enrollment_id === enrollId
                ? { ...el, sf_deeplink: res.data.deeplink_uri }
                : el
            )
          )
        })
      })
      .catch((error: any) => {
        setLoading(false)
        if (error.response) {
          toast.error(Helper.getErrorText(error), {
            position: 'bottom-center',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          })
        }
      })
  }

  const setIconBasedonStatus = useCallback(
    (_item: any) => {
      if (selectedProgram.includes(_item.program)) {
        if (_item.status === 'active') {
          return <CheckCircleIcon />
        } else if (_item.status === 'inactive') {
          return <CancelIcon />
        } else if (_item.status === 'temporarily_inactive') {
          return <RemoveCircleOutlineOutlinedIcon />
        }
      } else {
        return <RadioButtonUncheckedOutlinedIcon />
      }
    },
    [selectedProgram]
  )

  const renderCaseStatusIcon = (
    status: any,
    program: any,
    color_code: any,
    sf_deeplink: any,
    enrollment_id: any
  ) => {
    let IconType
    let colorCode = '#00c853'

    if (sf_deeplink === null) {
      IconType = AddCircleOutlinedIcon //SalesForceIcon
      colorCode = '#bdbdbd'
    } else if (status === 'ESCALATED') {
      IconType = BallotIcon //TrackChangesOutlined
      colorCode = '#FF0000'
    } else if (status === 'CLOSED') {
      IconType = BallotIcon //VisibilityOffOutlined
      colorCode = '#050505'
    } else IconType = BallotIcon

    const pendingCount = multiProgramConfirm.find((m) => m.program === program)?.pending || 0
    return (<Grid container className={classes.programIcons}>
      <Grid item>
        <Tooltip
          title={
            sf_deeplink === null
              ? 'Create case'
              : `View ${checkCondition(status !== 'NOT_AVAILABLE', Helper.toCamelCase(status), '')} case`
          }
          placement="bottom-start"
        >
          <IconButton
            disabled={
              !selectedProgram.includes(program) ||
              patientDetailStatus === 'loading'
            }
            className={classes.subHeaderStatusIcon}
            onClick={() => {
              if (sf_deeplink === null) createCase(program, enrollment_id)
            }}
            href={sf_deeplink}
            target="same"
          >
            <IconType
              style={{
                fill: `${selectedProgram.includes(program) ? colorCode : '#CBC7C780'
                  }`, fontSize: '24px'
              }}
            />
          </IconButton>
        </Tooltip>
      </Grid>
      {status !== 'CLOSED' && pendingCount === 0 &&
        <Grid item>
          <Tooltip title={sf_deeplink === null ? 'Case is not available' : 'Case is not closed'} placement="bottom-start">
            <WarningOutlinedIcon className={classes.warningIcon} />
          </Tooltip>
        </Grid>
      }
    </Grid>
    )
  }

  const getPrgSFCaseStatus = (program: any) => {
    const status = patientDetail?.program_enrollments?.find((f: { program: any }) => f.program === program)?.case_status
    const pendingCount = multiProgramConfirm.find((m) => m.program === program)?.pending || 0
    return (status !== 'CLOSED' && pendingCount >= 0) ? 'SF Case is not closed' : ''
  }

  const handleConfirmClose = () => setRemovePrgEnrollment(false)

  const confirmStatus = () => {
    setRemovePrgEnrollment(false)
    const eId = data.filter((el: any) => el.program === clickedProgram)[0]?.enrollment_id || ''
    clickedProgram && patientService.removeEnrollment(patientDetail.id, eId)
      .then((_res: any) => {
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
  }

  const getActionTitle = () => {
    const prgObj = patientDetail.program_enrollments?.find((f: { program: any }) => f.program === clickedProgram) || {}
    return `${prgObj.program_display_name || clickedProgram} - ${Helper.stageName(prgObj?.current_stage)}`
  }

  const handleToxicities = () => {
    handleClose()
    setLoading(true)
    clickedProgram && patientService.runToxicities(patientDetail.id, { programs: [clickedProgram] })
      .then((_res: any) => {
        setLoading(false)
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      }).catch((error: any) => {
        setLoading(true)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      })
  }

  return (
    <>
      {!isActionItem && (stageChange === true ||
        patientDetailStatus === 'loading' ||
        loading) && <LinearProgress className={classes.fullWidth} />}
      <Box className={classes.subHeaderContainerBg}>
        <Grid container spacing={1} direction="row">
          {data
            ?.sort((a: any, b: any) => a.program_display_name.toLowerCase() > b.program_display_name.toLowerCase() ? 1 : -1)
            ?.map((_e: any) => (
              <div key={`subheader-${_e.program}`} className={classes.subHeaderData}>
                <Grid item>
                  <Chip
                    icon={setIconBasedonStatus(_e)}
                    clickable={true}
                    disabled={patientDetailStatus === 'loading'}
                    onClick={() => _e.status !== 'inactive' && onChipClick(_e.program)}
                    sx={
                      selectedProgram.includes(_e.program)
                        ? {
                          width: '100%',
                          backgroundColor: _e.color_code,
                          height: '1.80rem',
                          borderRadius: '4px 0 0 4px',
                          cursor: _e.status === 'inactive' ? 'default' : 'pointer',
                          border: `${_e.status === 'inactive' ? '0.09rem dotted grey' : _e.color_code}`,
                          marginLeft: '2px',
                          padding: '5px 0px 5px 5px',
                          boxShadow: _e.status !== 'inactive' ?
                            `-3px 0px 5px -3px ${_e.color_code}, 0px 3px 5px 1px rgba(0,0,0,0.14), 0px 3px 5px 2px rgba(0,0,0,0.12)`
                            : 'none',
                          '&:hover': {
                            backgroundColor: `${_e.color_code} !important`,
                          }
                        }
                        : {
                          backgroundColor: 'white',
                          height: '1.75rem',
                          borderRadius: '4px 0 0 4px',
                          marginLeft: '2px',
                          padding: '5px 0px 5px 5px',
                          borderColor: 'white',
                          '&:hover': {
                            backgroundColor: `${_e.color_code} !important`,
                          }
                        }
                    }
                    label={getLable(_e)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    sx={
                      selectedProgram.includes(_e.program)
                        ? {
                          backgroundColor: _e.color_code,
                          height: '1.80rem',
                          borderRadius: '0 4px 4px 0',
                          borderLeft: 'none !important',
                          border: `${_e.status === 'inactive' ? '0.09rem dotted grey' : _e.color_code}`,
                          '&:hover': {
                            backgroundColor: _e.color_code,
                          }
                        }
                        : {
                          backgroundColor: 'white',
                          height: '1.80rem',
                          borderRadius: '0 4px 4px 0',
                          '&:hover': {
                            backgroundColor: 'white',
                          }
                        }
                    }
                    aria-controls={`simple-menu-${_e.program}`}
                    data-program={_e.program}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                  {clickedProgram === _e.program &&
                    <Menu
                      id={`simple-menu-${_e.program}`}
                      anchorEl={anchorEl}
                      keepMounted
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      sx={{
                        '.MuiPaper-root': {
                          boxShadow: `-3px 5px 5px -3px ${_e.color_code}, 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)`
                        }
                      }}
                    >
                      {getPrgActionList().map((l: any) =>
                        <>
                          {l.title === 'Start enrollment' &&
                            <MenuItem divider={true} sx={{ margin: '0px 0.3rem', padding: '2px' }} />
                          }
                          {renderProgramMenu(
                            l.title === 'Start enrollment' && _e.current_stage !== 'maintenance_surveillance'
                              ? _e.current_stage === 'enrollment' ? 'Start data intake' : 'Start maintenance'
                              : l.title,
                            _e.status,
                            l.callback
                          )}
                        </>
                      )}
                      <MenuItem divider={true} sx={{ margin: '0px 0.3rem', padding: '2px' }} />
                      {patientDetail?.program_enrollments?.find((f: { program: any }) => f.program === clickedProgram)?.status === 'active'
                        ? <MenuItem onClick={() => handlePrgStatus('INACTIVE')} className={classes.subTitle}>
                          Make inactive
                        </MenuItem>
                        :
                        <MenuItem onClick={() => handlePrgStatus('ACTIVE')} className={classes.subTitle}>
                          Make active
                        </MenuItem>
                      }
                      {renderProgramMenu('Remove enrollment', _e.status, handleRemoveEnrolment)}
                    </Menu>
                  }
                </Grid>
                <Grid item>
                  <Box>
                    {renderCaseStatusIcon(
                      _e.case_status,
                      _e.program,
                      _e.color_code,
                      _e.sf_deeplink,
                      _e.enrollment_id
                    )}
                  </Box>
                </Grid>
              </div>
            ))}
        </Grid>
      </Box >
      {isMonitor &&
        <AddMonitorComponent
          open={isMonitor}
          item={{
            title: 'Add monitor',
            label: 'Select monitor',
            monitorData: monitorData
          }}
          handleDialogClose={handleMonitorDialogClose}
          handleSubmit={handleMonitorSubmit}
          currProgram={clickedProgram}
        />
      }
      {isLifestyle &&
        <AddLifestyleDialogComponent
          open={isLifestyle}
          item={{
            title: 'Add lifestyle plan',
            label: 'Select plan',
            lifestyleData: lifestyleData
          }}
          currProgram={clickedProgram}
          handleDialogClose={handleLifestyleDialogClose}
          handleSubmit={handleLifestyleSubmit}
        />
      }
      {isActionItem &&
        <PendingItemsDialogComponent
          open={isActionItem}
          item={{
            title: getActionTitle(),
            program: clickedProgram,
            sfCaseStatus: getPrgSFCaseStatus(clickedProgram),
            actionList: {}
          }}
          handleDialogClose={handleActionDialogClose}
        />
      }
      {isPendingItem &&
        <OverrideItemsComponent
          open={isPendingItem}
          item={{
            title: getActionTitle(),
            program: clickedProgram,
          }}
          handleDialogClose={handlePendingDialogClose}
        />
      }
      {prgStatus !== null && (
        <ConfirmationDialogComponent
          open={prgStatus}
          content={`Are you sure to make program ${prgStatus.toLocaleLowerCase()}?`}
          handleConfirmClose={handlePrgStatusConfirmClose}
          confirmStatus={confirmPrgStatus}
        />
      )}
      {removePrgEnrollment && (
        <ConfirmationDialogComponent
          open={removePrgEnrollment}
          content={`Are you sure to remove program enrollment?`}
          handleConfirmClose={handleConfirmClose}
          confirmStatus={confirmStatus}
        />
      )}
    </>
  )
}

export default withStyles(stylesheet)(SubHeaderComponent)