import React from 'react';
import { Box } from '@mui/material'
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from "react-chartjs-2";
import stylesheet from "../detail-container.stylesheet";
import { withStyles } from '@mui/styles'
import { checkCondition } from './condition-check';
import trendlineLinear from 'chartjs-plugin-trendline';
ChartJS.register(...registerables, trendlineLinear);

interface IProps {
	labels: any,
	xLabels: boolean,
	color: string,
	value?: string,
	yValues: any,
	chartLabel: any,
	height: any,
	type: string,
	yMin?: any,
	yMax?: any
}

const ChartComponent: React.FC<IProps> = ({ labels, xLabels, color, value, yValues, chartLabel, height, type, yMin, yMax }) => {
	const annotationType = checkCondition(type === 'line',
		{
			type: 'line',
			mode: 'horizontal',
			scaleID: 'y-axis-0',
			value: value,
			borderColor: '#0b9c31',
		}, {
		type: 'box',
		display: true,
		xScaleID: "x-axis-0",
		yScaleID: "y-axis-0",
		yMin: yMin,
		yMax: yMax,
		backgroundColor: 'rgba(11, 156, 49, 0.20)',
		borderColor: 'rgba(11, 156, 49, 0.20)',
	})

	const data = {
		labels: labels,
		datasets: [
			{
				label: chartLabel,
				borderColor: color,
				borderWidth: 1.5,
				backgroundColor: 'grey',
				pointBackgroundColor: color,
				data: yValues,
				pointRadius: 2,
				pointHoverRadius: 2,
				//	autoSkip: true,
				//maxTicksLimit: 2,
				trendlineLinear: {
					colorMin: "black",
					colorMax: "black",
					lineStyle: "dotted",
					projection: false,
					width: 1.9,
				}
			},
		]
	}

	const options = {
		responsive: true,
		maintainAspectRatio: false,

		tooltips: {
			displayColors: false,
		},
		layout: {
			padding: 5
		},
		plugins: {
			legend: {
				display: false
			},
		},
		elements: {
			line: {
				tension: 0.35,
			},
		},
		scales: {
			y: {
				display: true,
				beginAtZero: false,
				//suggestedMax: yMax,
				suggestedMin: yMin,
				ticks: {
					display: true,
				},
				grid: {
					display: false,
				}
			},
			x: {
				barPercentage: 0.4,
				display: xLabels,
				fontSize: 8,
				autoSkip: false,
				maxRotation: 90,
				minRotation: 70,
				stepSize: 1,
				stacked: true,
				ticks: {
					display: true,
				},
				grid: {
					display: false
				}
			}
		},
		annotation: {
			annotations: [annotationType],
			drawTime: "afterDatasetsDraw"
		}
	};

	return <Box width='25rem' padding='0.5rem' sx={{ overflowX: 'auto', position: 'relative' }}>
		<Box sx={{
			height: '15rem',
			width: `${labels.length * 5}px !important`,
			minWidth: '25rem'
		}}>
			<Line data={data} options={options} height={height} />
		</Box>
	</Box>
}

export default withStyles(stylesheet)(ChartComponent);


