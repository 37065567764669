import React, { useEffect, useState } from 'react';
import {
  alpha, AppBar, Toolbar, IconButton, Menu, MenuItem, Tooltip, Button, Grid, Box, ListItemIcon, Divider, Typography
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SyncIcon from '@mui/icons-material/Sync';
import GetAppIcon from '@mui/icons-material/GetApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import AddBoxIcon from '@mui/icons-material/AddBox';
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useOktaAuth } from '@okta/okta-react'
import Logout from '@mui/icons-material/Logout'
import { PatientService } from '../../services/patient.service';
import atman_logo from '../../assets/atman_logo.svg';
import MultiProgramInfoHeaderComponent from '../patients/detail/info-header/multi-program-info-header.component'
import SubHeaderComponent from '../patients/detail/info-header/sub-header.component'
import { RootState, useAppThunkDispatch } from '../../redux/store';
import { useCurrentUser } from '../../utils/use-current-user';
import ConfirmationDialogComponent from '../patients/detail/common/confirmation-dialog.component';
import GeneratedNotesDialogComponent from '../patients/detail/common/generated-notes-dialog.component';
import { resetEncryptedToken } from '../../redux/common-data-slice';
import Helper from '../../utils/helper';
import { checkCondition } from '../patients/detail/common/condition-check';
import stylesheet from '../patients/detail/detail-container.stylesheet'
import { resetPatientsTable, setProgramChips } from '../../redux/common-data-slice';
import AddProgramDialogComponent from '../patients/detail/info-header/add-program.component';
import ErrosDialogComponent from '../patients/detail/info-header/patient-errors.component';
import { useUpdateDataAction } from '../../utils/update-details-hook';

interface IProps extends RouteComponentProps {
  openDrawerHandler: Function;
  history: any;
  location: any;
  match: any;
  isOktaEnabled: any;
}

const styles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 2,
  },
  search: {
    position: 'relative',
    borderRadius: '4px',
    backgroundColor: alpha('#fff', 0.15),
    '&:hover': {
      backgroundColor: alpha('#fff', 0.25),
    },
    marginRight: 2,
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: 7,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: 1,
    width: '100%',
  },
  sectionDesktop: {
    display: 'none',
  },
  sectionMobile: {
    display: 'flex',
  },
  iconCommonStyle: {
    opacity: '80%',
    color: '#ffffff',
  },
  profileIcon: {
    width: '30px',
    height: '30px',
    opacity: '80%',
    color: '#ffffff',
  },
  navLink: {
    borderRadius: '25px',
    padding: '6px 24px 6px 21px',
    width: '44px',
    height: '22px',
    background: '#ffffff',
    opacity: '70%',
    display: 'inline-block',
    fontSize: '18px',
    color: '#000000',
    textDecoration: 'none solid rgb(0, 0, 0)',
    textAlign: 'center',
  },
  activeLink: {
    background: '#f5842e',
    cursor: 'pointer',
    fontSize: '18px',
    height: '30px',
    color: 'white',
  },
  appLogo: {
    height: '45px',
    width: 'auto',
    // [theme.breakpoints.up('md')]: {
    //   height: '45px',
    //   width: 'auto',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   height: '45px',
    //   width: '140px',
    // },
  },
  addPrgIcon: {
    fill: 'orange',
    width: '20px',
    height: '20px'
  },
  inactiveLink: {
    background: '#000000',
    cursor: 'pointer',
    color: 'white',
    fontSize: '16px',
    height: '30px',
  },
  linkText: {
    textDecoration: 'none',
  },
  hideSearch: {
    display: 'none',
  },
  menuItem: {
    padding: '0.4rem 0.8rem !important',
    fontSize: '0.8rem !important',
    '&.MuiMenuItem-root, & .MuiListItemIcon-root': {
      paddingLeft: '0.3rem !important',
    },
    '&:hover': {
      color: '#FF7015 !important',
      '& .MuiListItemIcon-root': {
        color: '#FF7015 !important',
      }
    },
  },

  logoutBtn: {
    color: '#ffffff !important',
    padding: '0rem !important',
    fontSize: '0.8rem !important',
    letterSpacing: 0,
    '&:hover': {
      color: '#FF7015 !important',
    },
  },
}));

const OktaToolbarComponent: React.FC<IProps> = ({ openDrawerHandler, isOktaEnabled }) => {
  const [mobileAnchorEl, setMobileAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [syncOpen, setSyncOpen] = useState(false)
  const [confirmNoteOpen, setconfirmNoteOpen] = useState(false)
  const loggedInUser = useSelector((state: RootState) => state.commonData.loggedInUser)
  const { currentRole } = useCurrentUser()
  const patientService = new PatientService();
  const url = useLocation();
  const dispatch = useAppThunkDispatch()
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const selectedProgram = useSelector((state: RootState) => state.commonData.selectedProgram)
  const programsSet = useSelector((state: RootState) => state.metaData.metadata?.PROGRAMS)
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)
  const { oktaAuth } = useOktaAuth();
  const [showAppTitle,] = useState(true);
  const [visitNote, setvisitNote] = useState(null)
  const [isCloseoutNote, setIsCloseoutNote] = useState(false)
  const [isAddPrg, setIsAddPrg] = useState(false)
  const [isShowErrors, setIsShowErrors] = useState(false)
  const classes = styles()
  const handleConfirmClose = () => setConfirmOpen(false)
  const handleConfirmSyncClose = () => setSyncOpen(false)

  useEffect(() => {
    if (patientDetail?.error_data && Object.keys(patientDetail?.error_data)?.length > 0) {
      const details = <Typography variant="body2">{`Patient loaded with error.`}
        <Button variant="text" onClick={handleShowErrors}>Click here</Button>
      </Typography>
      toast.error(details, Helper.bottom_center())
    }
  }, [patientDetail])

  const handleConfirm = () => {
    handleMobileMenuClose();
    setConfirmOpen(true)
  }

  const handleConfirmNoteClose = () => {
    setconfirmNoteOpen(false)
    setvisitNote(null)
    setIsCloseoutNote(false)
  }

  const confirmStatus = () => {
    const payload = {
      programs: patientDetail.program_enrollments.map((e: any) => e.program).toString()
    }
    patientService.resetPatient(patientDetail.id, payload).then((res: any) => {
      setConfirmOpen(false)
      const newRefId = res?.data?.ref_id || ''
      const newURL = (window.location.href)?.replace(patientDetail.id, newRefId);
      (newRefId !== '' && newRefId !== patientDetail.id)
        ? window.location.replace(newURL)
        : updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })


    }).catch(() => {
      setConfirmOpen(false)
      toast.error('Failed to reset patient.', Helper.bottom_center())
    })
  }

  const handleMobileMenuClose = () => setMobileAnchorEl(null)
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => setMobileAnchorEl(event.currentTarget);

  const onExport = () => {
    handleMobileMenuClose()
    patientService.exportPatient(patientDetail.id).then((res: any) => {
      const exportUrl = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = exportUrl;
      link.setAttribute('download', `${patientDetail.id}.json`);
      document.body.appendChild(link);
      link.click();
      toast.success(`Patient exported successfully.`, Helper.bottom_center())
    }).catch((error) => {
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const isDisabledSync = () => {
    if (patientDetail?.source_details?.length > 0)
      return patientDetail?.source_details[0][0] === null ? false : true
    return false
  }

  const checkSouceDeatils = () => {
    if (patientDetail?.source_details?.length > 0) {
      const srcDetails = patientDetail?.source_details[0] || []
      if (srcDetails?.length > 0)
        return ['EPIC', 'ELATION']?.includes(srcDetails[0])
    }
  }

  const confirmSyncStatus = () => {
    const srcDetails = patientDetail?.source_details[0] || []
    if (srcDetails?.length > 0) {
      const type = srcDetails[0]
      const mrn = srcDetails[1]

      const prgList = patientDetail?.program_enrollments?.map((i: any) => i?.program)?.toString()
      if (type === 'EPIC') {
        patientService.syncEPICPatients(mrn, prgList).then((res: any) => {
          setSyncOpen(false)
          updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })

        }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
      } else if (type === 'ELATION') {
        patientService.syncELATIONPatients(mrn, prgList).then((res: any) => {
          setSyncOpen(false)
          updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })

        }).catch((error) => {
          setSyncOpen(false)
          toast.error(Helper.getErrorText(error), Helper.bottom_center())
        })
      }
    }
  }

  const onGenerateNote = () => {
    handleMobileMenuClose()
    setvisitNote(null)
    setconfirmNoteOpen(true)
    setIsCloseoutNote(false)
    patientService.generateNote(patientDetail.id).then((res: any) => {
      setvisitNote(res.data.generated_note_text)
      toast.success(`Patient note is generated`, Helper.bottom_center())
    }).catch((error) => {
      setvisitNote(null)
      setconfirmNoteOpen(false)
      setIsCloseoutNote(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const handleCloseOutNote = (prg: any) => {
    setconfirmNoteOpen(true)
    setIsCloseoutNote(true)
    setvisitNote(null)
    prg && patientService.closeoutNote(patientDetail.id, { program: prg }).then((res: any) => {
      if (res?.data?.generated_note_text) {
        toast.success(res?.data?.message || `Patient note closeout successfully`, Helper.bottom_center())
        setvisitNote(res?.data?.generated_note_text)
      }
    }).catch((error) => {
      setconfirmNoteOpen(false)
      setvisitNote(null)
      setIsCloseoutNote(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const onWriteNote = (visitNoteEditedText: any) => {
    const payload = { 'visit_note_text': visitNoteEditedText }
    patientService.submitNote(patientDetail.id, payload).then((res: any) => {
      toast.success(`Patient note is created on Elation`, Helper.bottom_center())
      setconfirmNoteOpen(false)
      setvisitNote(null)
      setIsCloseoutNote(false)
    }).catch((error) => {
      setconfirmNoteOpen(false)
      setvisitNote(null)
      setIsCloseoutNote(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const redirectToHome = () => {
    dispatch(resetPatientsTable())
    dispatch(setProgramChips([]))
  }

  const menuId = 'primary-search-account-menu';

  const logout = async () => {
    handleMobileMenuClose()
    await oktaAuth.revokeAccessToken()
    oktaAuth.signOut();
    dispatch(resetEncryptedToken())
  }

  const handleAddProgram = () => {
    handleMobileMenuClose()
    setIsAddPrg(true)
  }
  const handleShowErrors = () => {
    handleMobileMenuClose()
    setIsShowErrors(true)
  }

  const getPrgList = () => {
    let prgList = programsSet?.filter((i: any) => i.program_type !== "evaluation")

    prgList = prgList?.map((item: any) => {
      return (selectedProgram.includes(item.name))
        ? { ...item, checked: true }
        : { ...item }
    }) || []

    return [...prgList]?.sort((a: any, b: any) => a.display_name > b.display_name ? 1 : -1)
  }
  const handlePrgDialogClose = () => setIsAddPrg(false)
  const handleErrDialogClose = () => setIsShowErrors(false)

  const handlePrgDialogSubmit = (data: any) => {
    handlePrgDialogClose()
    data && patientService.createEnrollment(patientDetail.id, { 'program_name': data })
      .then((_res: any) => {
        toast.success('Program added successfully', Helper.bottom_center())
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      }).catch((error) => {
        setConfirmOpen(false)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      })
  }

  const handleGenerateExtract = (hospital: any) => {
    handleMobileMenuClose()
    hospital = 'optum' //localStorage.getItem("atman-hospitals")
    patientService.generateExports(hospital).then((res: any) => {
      toast.success('Report generation started.', Helper.bottom_center())
    }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
  }

  const handleDownloadExtract = (hospital: any) => {
    handleMobileMenuClose()
    patientService.extractTreatmentReport(hospital).then((res: any) => {
      Helper.handleReportFile(res?.data, 'treatment_report')
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))
    patientService.extractHospitalInteractionReport(hospital).then((res: any) => {
      Helper.handleReportFile(res?.data, 'interaction_report')
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))
    patientService.extractHospitalBPReport(hospital).then((res: any) => {
      Helper.handleReportFile(res?.data, 'bp_report')
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))
    patientService.extractHospitalRXReport(hospital).then((res: any) => {
      Helper.handleReportFile(res?.data, 'rx_report')
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))
    patientService.extractHospitalLabReport(hospital).then((res: any) => {
      Helper.handleReportFile(res?.data, 'lab_report')
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))
  }

  const getExtractMenu = () => {
    return <>
      {window.location.href === `${window.location.origin}/` && (
        <>
          <MenuItem onClick={() => handleGenerateExtract('optum')} className={classes.menuItem}>
            <ListItemIcon><GroupAddIcon sx={{ fontSize: "1.05rem", paddingLeft: '0.2rem' }} /></ListItemIcon>Generate extracts
          </MenuItem>
          <MenuItem onClick={() => handleDownloadExtract('optum')} className={classes.menuItem}>
            <ListItemIcon><GetAppIcon fontSize="small" /></ListItemIcon>Download extracts
          </MenuItem>
          <Divider sx={{ margin: '5px 0px !important' }} />
        </>
      )}
    </>
  }

  const renderMenu = (
    <Menu
      id={menuId}
      open={Boolean(mobileAnchorEl)}
      anchorEl={mobileAnchorEl}
      onClose={handleMobileMenuClose}
      keepMounted
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ top: '6%', left: '1%' }}
    >
      {checkCondition(url.pathname.endsWith('/details') && loggedInUser?.roles?.some((s: string) => s === 'NAVIGATOR' || s === 'PROVIDER'),
        <>
          <MenuItem onClick={handleAddProgram} className={classes.menuItem}>
            <ListItemIcon><AddBoxIcon fontSize="small" /></ListItemIcon>Add program
          </MenuItem>
          <MenuItem onClick={onExport} className={classes.menuItem}>
            <ListItemIcon><GetAppIcon fontSize="small" /></ListItemIcon>Export patient
          </MenuItem>
          <MenuItem onClick={onGenerateNote} className={classes.menuItem}>
            <ListItemIcon><BlurLinearIcon fontSize="small" /></ListItemIcon>Generate encounter note
          </MenuItem>
          {patientDetail.is_test_patient
            ? <MenuItem onClick={handleConfirm} className={classes.menuItem}>
              <ListItemIcon><RefreshIcon fontSize="small" /></ListItemIcon>Reset patient
            </MenuItem>
            : <> {checkSouceDeatils() &&
              <MenuItem
                onClick={() => {
                  handleMobileMenuClose();
                  setSyncOpen(isDisabledSync() ? true : false)
                }}
                className={classes.menuItem}
                disabled={!isDisabledSync()}
              >
                <ListItemIcon><SyncIcon fontSize="small" /></ListItemIcon>Sync patient
              </MenuItem>
            }
            </>
          }
          {patientDetail?.error_data && Object.keys(patientDetail?.error_data)?.length > 0 &&
            <MenuItem onClick={handleShowErrors} className={classes.menuItem}>
              <ListItemIcon><ErrorOutlineIcon sx={{ fill: 'rgb(249 0 0)' }} fontSize="small" /></ListItemIcon>Show errors
            </MenuItem>
          }
        </>
      )}
      {isOktaEnabled && (
        <>
          {(url.pathname.endsWith('/details') &&
            loggedInUser?.roles?.some((s: string) => s === 'NAVIGATOR' || s === 'PROVIDER')) &&
            <Divider sx={{ margin: '5px 0px !important' }} />
          }
          {getExtractMenu()}
          <MenuItem onClick={logout} className={classes.menuItem}>
            <ListItemIcon><Logout fontSize="small" /></ListItemIcon>Logout
          </MenuItem>
        </>
      )}
    </Menu>
  )

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ background: currentRole === 'NAVIGATOR' ? '#0047AB' : '#000000' }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton
              edge='start'
              className={classes.menuButton}
              sx={{ display: { md: 'none' } }}
              color='inherit'
              aria-label='open drawer'
              onClick={() => openDrawerHandler()}
            >
              <MenuIcon />
            </IconButton>
            {showAppTitle && (
              <Link to={'/'}>
                <img src={atman_logo} alt='Atman Health' className={classes.appLogo} onClick={() => redirectToHome()} />
              </Link>
            )}
            <Box sx={{ width: '100%' }}>
              {(url.pathname.endsWith('/details') && loggedInUser?.roles?.some((s: string) => s === 'NAVIGATOR' || s === 'PROVIDER')) &&
                <MultiProgramInfoHeaderComponent handleClick={{}} />
              }
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box className={classes.sectionDesktop} sx={{ display: { md: 'flex' } }}>
              {checkCondition(currentRole,
                <Grid item xl={3}>
                  <Button variant="text" className={classes.logoutBtn} onClick={handleMobileMenuOpen}>
                    {checkCondition(loggedInUser?.name !== undefined, `${loggedInUser?.name} (${Helper.toCamelCaseRole(currentRole) || '-'})`, '-')}
                    <MoreVertIcon />
                  </Button>
                </Grid>
              )}
            </Box>
            <Box className={classes.sectionMobile} sx={{ display: { md: 'none' } }}>
              <IconButton
                aria-label='show more'
                aria-controls={'primary-menu-mobile'}
                aria-haspopup='true'
                onClick={handleMobileMenuOpen}
                color='inherit'
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {(url.pathname.endsWith('/details') &&
          loggedInUser?.roles?.some((s: string) => ['NAVIGATOR', 'PROVIDER'].includes(s))) &&
          <SubHeaderComponent handleCloseOutNote={handleCloseOutNote} />
        }
        {/* {loggedInUser?.roles?.includes('PATIENT') &&
          <PatientRoleSubheaderComponent />
        } */}
        {renderMenu}
      </Box>
      {checkCondition(confirmOpen,
        <ConfirmationDialogComponent
          open={confirmOpen}
          content={`Are you sure you want to reset the patient details?`}
          handleConfirmClose={handleConfirmClose}
          confirmStatus={confirmStatus}
        />)
      }
      {syncOpen &&
        <ConfirmationDialogComponent
          open={syncOpen}
          content={`Are you sure you want to sync patient details?`}
          handleConfirmClose={handleConfirmSyncClose}
          confirmStatus={confirmSyncStatus}
        />
      }
      {isAddPrg &&
        <AddProgramDialogComponent
          open={isAddPrg}
          item={{
            title: 'Add Program',
            label: 'Select Program',
            prgData: getPrgList()
          }}
          handleDialogClose={handlePrgDialogClose}
          handleSubmit={handlePrgDialogSubmit}
        />
      }
      {confirmNoteOpen && (
        <GeneratedNotesDialogComponent
          open={confirmNoteOpen}
          content={visitNote}
          isCloseoutNote={isCloseoutNote}
          handleConfirmNoteClose={handleConfirmNoteClose}
          submitNote={onWriteNote}
        />
      )}
      {isShowErrors &&
        <ErrosDialogComponent
          open={isShowErrors}
          item={{
            title: 'Error Details',
            data: patientDetail?.error_data
          }}
          handleDialogClose={handleErrDialogClose}
        />
      }
    </>
  );
};

export default withStyles(stylesheet)(OktaToolbarComponent);