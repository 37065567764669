import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Card, CardContent, Typography, Grid, Box, IconButton, Menu, Tooltip,
  MenuItem, Popover, ClickAwayListener, Stack, Button
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from "../detail-container.stylesheet";
import EditIcon from '@mui/icons-material/Edit'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ColorBubbleComponent from './color-bubble.component'
import { checkCondition } from './condition-check';
import ContextualHelpComponent from "./contextual-help.component";
import MedEvidenceComponent from './med-evidence.component';
import RationaleDialogComponent from './rationale-dialog.component';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import Helper from '../../../../utils/helper';

interface IIcons {
  iconType: string,
  callback: any
}

interface IProps {
  id: number,
  name?: any
  leftHeader: any | ReactElement,
  leftHeader1?: string | ReactElement,
  rightHeader: string | ReactElement,
  subHeader?: string | ReactElement,
  subHeader1?: string | ReactElement,
  bottomText: string | ReactElement,
  source?: any,
  icons: IIcons[],
  needs_confirmation: boolean | undefined,
  needs_update: boolean | undefined,
  is_recommended?: boolean,
  programs?: string[],
  overridenPrograms?: string[],
  classes: ClassNameMap,
  is_referred: boolean,
  is_overridden: boolean,
  isOverrideItem?: boolean,
  last_sync_date?: string,
  onMedDetailsClick?: any,
  onRefClick: any,
  onDeleteMedicationClick?: any,
  onOverrideClick?: any,
  patientId: string,
  entityRef: string,
  is_care_plan_non_recc?: boolean
  category?: string
  titration_status?: any
  recommendation_source?: '',
  is_new_med?: any
}
interface IRefState {
  created_on: number;
  detail: string
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '0.8rem',
    border: '0.1rem solid #DB2323',
  },
  arrow: {
    color: '#DB2323'
  }
}))(Tooltip);

const CardLayoutComponent: React.FC<IProps> = ({ id, name, leftHeader, leftHeader1, rightHeader, subHeader,
  subHeader1, bottomText, source, icons, needs_confirmation, needs_update, is_recommended = false,
  programs = [], overridenPrograms = [], classes, is_referred, is_overridden, isOverrideItem, last_sync_date, onMedDetailsClick,
  onDeleteMedicationClick, onRefClick, onOverrideClick, patientId, entityRef, is_care_plan_non_recc,
  category = '', titration_status = '', recommendation_source = '', is_new_med = false }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openMedEvidenceDialog, setOpenMedEvidenceDialog] = useState(false)
  const [cardAnchorEl, setCardAnchorEl] = useState<null | HTMLElement>(null);
  const [openRationale, setOpenRationale] = useState(false)
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)

  const getProgramName = (prg: string) => patientDetail?.program_enrollments
    ?.filter((m: { program: any }) => m.program === prg)?.[0]?.program_display_name || prg

  let prgStatus = Helper.isPrgInActive(patientDetail?.program_enrollments, programs)
  let prgStatusColor = {
    fill: prgStatus ? 'rgba(0, 0, 0, 0.26) !important' : '',
    cursor: prgStatus ? 'default' : 'hand'
  }
  useEffect(() => {
    prgStatus = Helper.isPrgInActive(patientDetail?.program_enrollments, programs)
    prgStatusColor = {
      fill: prgStatus ? 'rgba(0, 0, 0, 0.26) !important' : '',
      cursor: prgStatus ? 'default' : 'hand'
    }
  }, [patientDetail])

  const sortProgramNyName = () => {
    if (programs?.length <= 1)
      return programs || []

    return programs?.map((a: any) => getProgramName(a))
      ?.sort((a: any, b: any) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
      ?.map((a: any) =>
        patientDetail?.program_enrollments?.filter((m: { program_display_name: any }) => m.program_display_name === a)?.[0]
          ?.program || a) || programs
  }

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    setCardAnchorEl(event.currentTarget);
  };

  const handleCardClose = () => {
    setCardAnchorEl(null);
  };

  const onNoRef = (event: any) => {
    setOpen(false)
    setAnchorEl(event.currentTarget);
  }

  const handleMoreClick = (event: any) => {
    setOpen(false)
    onNoRef(event)
  };

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null);
  };

  const getIcons = (_value: string) => {
    let icon
    switch (_value) {
      case 'edit':
        icon = <EditIcon sx={prgStatusColor} className={classes.editIcon} />
        break
      case 'description':
        icon = <InsertDriveFileOutlinedIcon className={classes.iconFontSize} />
        break
      case 'visible':
        icon = <VisibilityOutlinedIcon className={classes.iconFontSize} />
        break
      case 'more':
        icon = <MoreVertOutlinedIcon className={classes.iconFontSize} />
        break
      case 'delete':
        icon = <DeleteOutlinedIcon sx={prgStatusColor} className={classes.iconFontSize} />
        break
      default:
        icon = <span></span>
        break
    }
    return icon
  }

  const getCompactIcons = (_value: string) => {
    let icon
    switch (_value) {
      case 'edit':
        icon = <EditIcon sx={prgStatusColor} className={classes.editIcon} />
        break
      case 'description':
        icon = <InsertDriveFileOutlinedIcon sx={prgStatusColor} className={classes.editIcon} />
        break
      case 'visible':
        icon = <VisibilityOutlinedIcon className={classes.visibleIconSize} />
        break
      case 'delete':
        icon = <DeleteOutlinedIcon sx={prgStatusColor} className={classes.compactMenuIcon} />
        break
      default:
        icon = <span></span>
        break
    }
    return icon
  }

  const getActions = useCallback(
    (_id: any, _value: string, _confirmOrUpdate: boolean | undefined, _callBackFunction: any, _is_recommended: boolean, _classes: any) => {
      return <IconButton
        disabled={prgStatus}
        onClick={() => checkCondition(typeof (_callBackFunction) === 'function', _callBackFunction(_id))}
        className={checkCondition(_confirmOrUpdate && _value === 'edit', _classes.corfirmButtonStyle, _classes.iconBaseBorder)}
      >
        <Typography style={checkCondition((_is_recommended && _value !== 'edit'), { color: '#FFFFFF', height: '1.25rem' }, { height: '1.25rem' })} >{getIcons(_value)} </Typography>
      </IconButton>
    },
    [anchorEl],
  )

  const getMoreActions = (
    _id: any,
    _value: string,
    _confirmOrUpdate: boolean | undefined, _callBackFunction: any,
    _is_recommended: boolean,
    prgStatus: boolean,
    _classes: any) => {

    return icons.map((e, i, arr) => {
      if (e.iconType !== 'more') {
        return <MenuItem key={`more-actions-${i}`} className={classes.compactMenuItem}>
          <IconButton
            onClick={() => {
              if (e.iconType === 'visible' || !prgStatus) {
                handleCardClose()
                checkCondition(typeof (e.callback) === 'function', e.callback(_id))
              }
            }}
            className={checkCondition(((_confirmOrUpdate || entityRef === 'med_reconcilation') && e.iconType === 'edit' && !prgStatus), _classes.corfirmCompactButtonStyle, _classes.zeroPadding)}
          >
            <Typography style={checkCondition((_is_recommended && e.iconType !== 'edit'), { color: '#FFFFFF', paddingTop: '5px' }, { paddingTop: '5px' })} >
              {getCompactIcons(e.iconType)} </Typography>
          </IconButton>
        </MenuItem >
      }
    })
  }

  const getClass = () => {
    let style
    switch (true) {
      case isOverrideItem === true:
        style = classes.overrideItemCard
        break;
      case entityRef === 'med_reconcilation' && is_new_med:
        style = classes.reconcileBgNewMed
        break;
      case entityRef === 'med_reconcilation_mapped':
        style = classes.reconcileBg
        break;
      case entityRef === 'med_reconcilation_quarantine':
        style = classes.reconcileQuarantineBg
        break
      case is_referred && recommendation_source.includes('provider') && is_recommended:
        style = (
          titration_status).includes('initiate')
          ? classes.recommendedRefDisconBackground
          : ((titration_status).includes('downtitrate')
            || (titration_status).includes('discontinue')
            || (titration_status).includes('prior dose')
            || (titration_status).includes('lowest dose'))
            ? classes.recommendedRefDisconBackground
            : classes.refRecomendBackground
        break
      case is_referred:
        style = classes.referralBackground
        break
      case is_overridden:
        style = classes.overrideBackground
        break
      case is_recommended:
        style = recommendation_source.includes('provider') && ((titration_status).includes('downtitrate')
          || (titration_status).includes('discontinue')
          || (titration_status).includes('prior dose')
          || (titration_status).includes('lowest dose'))
          ? classes.recommendedDisconBackground
          : classes.recommendedBackground
        break
      case needs_update:
        style = classes.confirmCardBackground
        break
      case needs_confirmation:
        style = classes.confirmCardBackground
        break
      case is_care_plan_non_recc:
        style = classes.carePlanBorder
        break
      default:
        style = '-'
        break
    }
    return style
  }

  const onHelpClick = () => {
    handleCardClose()
    setOpenDialog(true)
  }

  const handleRationale = () => {
    handleCardClose()
    setOpenRationale(true)
  }

  const handleRationaleClose = () => setOpenRationale(false)

  const onMedEvidenceClick = () => {
    handleMedEvidenceCardClose()
    setOpenMedEvidenceDialog(true)
  }

  const handleMedEvidenceCardClose = () => setOpenMedEvidenceDialog(false)
  const closeDialog = () => setOpenDialog(false)
  const closeMedEvidenceDialog = () => setOpenMedEvidenceDialog(false)

  const getCardMoreActions = (isMedCard: Boolean) => {
    return <Typography align="right">
      {icons.map((e, i, arr) =>
        <React.Fragment key={`${id}-${i}`}>
          {checkCondition(e.iconType === 'more',
            <React.Fragment key={`${id}-${e.iconType}`}>
              <Popover
                id={`compact-menu-${id}`}
                open={Boolean(cardAnchorEl)}
                anchorEl={cardAnchorEl}
                onClose={handleCardClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Box className={classes.compactMenuCard}>
                  {last_sync_date && entityRef == 'measurement' &&
                    <MenuItem className={classes.compactMenuItem} >
                      <Typography color="textSecondary" variant="subtitle2">
                        <small>{last_sync_date}</small>
                      </Typography>
                    </MenuItem>
                  }
                  {getMoreActions(id, e.iconType, needs_confirmation ?? needs_update, e.callback, is_recommended, prgStatus, classes)}
                  {!['clinical_scores', 'icd10_codes', 'monitor', 'other_med_discontinue', 'med_reconcilation', 'med_reconcilation_mapped', 'med_reconcilation_quarantine', 'toxicities'].includes(entityRef) &&
                    <Tooltip title="Referral">
                      <MenuItem className={classes.compactMenuItem} onClick={() => !prgStatus && onRefClick()}>
                        <NotificationsActiveOutlinedIcon sx={prgStatusColor} className={classes.notValCompactMenuIcon} />
                      </MenuItem>
                    </Tooltip>
                  }
                  {((['measurement', 'calculator'].includes(entityRef) && (needs_update || needs_confirmation)
                    || entityRef === 'care_plan'
                  ))
                    && <MenuItem className={classes.compactMenuItem} onClick={() => !prgStatus && onOverrideClick()}>
                      <DoubleArrowRoundedIcon sx={prgStatusColor} className={classes.overideIcon} />
                    </MenuItem>
                  }
                  {['measurement', 'condition_evaluation'].includes(entityRef) &&
                    <Tooltip title="Rationale">
                      <MenuItem className={classes.compactMenuItem} onClick={() => !prgStatus && handleRationale()}>
                        <PrivacyTipOutlinedIcon sx={prgStatusColor} className={classes.overideIcon} />
                      </MenuItem>
                    </Tooltip>
                  }
                  {['care_plan', 'other_medication', 'other_med_discontinue', 'med_reconcilation', 'med_reconcilation_mapped', 'med_reconcilation_quarantine'].includes(entityRef) &&
                    <Tooltip title="Med details">
                      <MenuItem className={classes.compactMenuItem}
                        onClick={() => {
                          handleCardClose();
                          onMedDetailsClick();
                        }}
                      >
                        <InfoOutlinedIcon className={classes.overideIcon} />
                      </MenuItem>
                    </Tooltip>
                  }
                  {['reported_med_intolerance'].includes(entityRef) &&
                    <Tooltip title="Restricted Med details">
                      <MenuItem className={classes.compactMenuItem}
                        onClick={() => {
                          handleCardClose();
                          onMedDetailsClick()
                        }}
                      >
                        <InfoOutlinedIcon className={classes.overideIcon} />
                      </MenuItem>
                    </Tooltip>
                  }
                  {entityRef === 'care_plan' &&
                    <Tooltip title="Med evidence">
                      <MenuItem className={classes.compactMenuItem}
                        onClick={() => {
                          handleCardClose();
                          onMedEvidenceClick()
                        }}
                      >
                        <NoteAddOutlinedIcon fontSize="small" className={classes.overideIcon} />
                      </MenuItem>
                    </Tooltip>
                  }
                  {(!['toxicities', 'problem', 'monitor', 'program_lifestyle'].includes(entityRef) &&
                    !(entityRef === 'calculator' && category === 'conditions'))
                    &&
                    <Tooltip title="Help">
                      <MenuItem className={classes.compactMenuItem} onClick={() => onHelpClick()}>
                        <HelpOutlineOutlinedIcon className={classes.overideIcon} />
                      </MenuItem>
                    </Tooltip>
                  }
                </Box>
              </Popover>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Typography>
  }

  const renderMiniCard = () => {
    return <Box mb={1} className={classes.customMiniCard}>
      <Card variant="outlined" className={classes.compactCard}>
        <Box>
          <CardContent className={[getClass(), classes.cardsContentPadding].join(' ')}>
            <Grid container onClick={handleCardClick} className={classes.compactCardContent}>
              <Grid item>
                <Grid container spacing={1} direction="row">
                  <Grid item>
                    <Typography variant="body2" className={classes.desc} style={checkCondition(is_recommended, { color: '#F7AC40' }, {})}>
                      {leftHeader}
                      <ColorBubbleComponent selectedProgramList={programs} overridenProgramList={overridenPrograms} />
                    </Typography>
                  </Grid>
                  {subHeader &&
                    <Grid item>
                      <Typography className={classes.subHeaderTitle} variant="subtitle2" color="textSecondary">
                        {['measurement', 'calculator'].includes(entityRef)
                          ? subHeader
                          : `(${subHeader})`
                        }
                      </Typography>
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs={category === 'conditions' ? 2 : 3}>
                <Stack direction="row" justifyContent="end" spacing={1}>
                  <Typography className={classes.subHeaderTitle} align="right" variant="subtitle2" color="textSecondary">
                    <small>{bottomText}</small>
                  </Typography>
                  {(category !== 'conditions' && entityRef !== 'calculator') &&
                    <Typography className={classes.subTitle}>{rightHeader} </Typography>
                  }
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
      {!['icd10_codes', 'clinical_scores'].includes(entityRef) && getCardMoreActions(false)}

    </Box>
  }

  const getMedIntolId = () => {
    return entityRef === 'reported_med_intolerance'
      ? `${leftHeader?.med_id}/${leftHeader?.med_type}`
      : id
  }

  const renderMedCard = () => {
    return <Box className={classes.fullWidth}>
      <Card variant="outlined" className={entityRef === 'care_plan' ? classes.compactMedCard : classes.customCard}>
        <CardContent className={[getClass(), classes.zeroPadding].join(' ')}>
          <Grid container onClick={handleCardClick} direction='column' className={classes.compactCardContent}>
            <Grid item className={classes.fullWidth}>
              <Grid container spacing={0} direction="row" justifyContent="flex-start">
                <Grid item className={rightHeader !== '' ? classes.evalTitleHeader : classes.fullWidth}>
                  <Typography variant="body2" className={classes.desc} style={checkCondition(is_recommended, { color: '#F7AC40' }, {})}>
                    {entityRef === 'reported_med_intolerance' ? leftHeader?.name : leftHeader}
                    <ColorBubbleComponent selectedProgramList={programs} />
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" className={classes.desc} style={checkCondition(is_recommended, { color: '#FFFFFF' }, {})} align="right" >
                    {rightHeader}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {source &&
              <Grid item className={classes.subHeaderTitle}>
                <Typography className={classes.cardDate} variant="subtitle2" color="textSecondary">
                  {source}
                </Typography>
              </Grid>
            }
            <Grid item className={classes.fullWidth}>
              <Grid container spacing={1} direction="row">
                <Grid item sx={{ width: '88%' }} className={classes.subHeaderTitle}>
                  <Typography color={checkCondition(is_recommended, undefined, 'textSecondary')}
                    sx={checkCondition(is_recommended, { color: '#FFFFFFB3' }, {})}
                    variant="subtitle2"
                    onClick={handleCardClick}
                  >
                    {subHeader}
                  </Typography>
                </Grid>
                <Grid item sx={{ width: '12%' }}>
                  <Typography className={classes.cardDate} variant="subtitle2" color="textSecondary">
                    <small>{bottomText}</small>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {getCardMoreActions(true)}
        </CardContent>
      </Card>
    </Box>
  }

  return (<>
    <Grid container direction="column">
      {['measurements', 'conditions'].includes(category)
        ? renderMiniCard()
        : <>
          {(category === 'medications' && ['clinical_scores', 'med_reconcilation', 'med_reconcilation_quarantine', 'med_reconcilation_mapped', 'other_medication', 'other_med_discontinue', 'monitor', 'program_lifestyle', 'medications', 'reported_med_intolerance', 'care_plan', 'problems', 'toxicities'].includes(entityRef))
            ? renderMedCard()
            :
            <Box mb={1} className={classes.customCard}>
              <Card variant="outlined" className={`${getClass()} ${classes.fullWidth}`}>
                <CardContent className={classes.programCardContent}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="baseline" className={classes.initialFlexwrap}>
                    <Grid item width={rightHeader ? '72%' : '100%'}>
                      <Typography variant="subtitle2" className={classes.desc}
                        sx={{ color: is_recommended ? '#F7AC40' : '' }}
                      >
                        {leftHeader}
                        <ColorBubbleComponent selectedProgramList={programs} />
                      </Typography>
                    </Grid>
                    <Grid item className={classes.subTitle} width={'auto'}>
                      <Typography variant="body2" className={classes.desc} align="right"
                        sx={{ color: is_referred ? '#1e141487' : '#FFF' }}>
                        {rightHeader}
                      </Typography>
                    </Grid>
                  </Grid>
                  {checkCondition(subHeader, <Grid container direction="row" alignItems="baseline" className={classes.initialFlexwrap} >
                    <Grid item className={classes.subHeaderTitle}>
                      <Typography variant="subtitle2" sx={{ color: is_referred ? '#1e141487' : '#FFF' }}>
                        {subHeader}
                      </Typography>
                    </Grid>
                  </Grid>)}
                  {checkCondition(leftHeader1,
                    <Grid container direction="row" justifyContent="space-between" alignItems="baseline" className={classes.initialFlexwrap}>
                      <Grid item>
                        <Typography className={classes.descleftHeader} variant="subtitle2">
                          {leftHeader1}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {checkCondition(subHeader1, <Grid container direction="row" alignItems="baseline" className={classes.initialFlexwrap} >
                    <Grid item className={classes.subHeaderTitle}>
                      <Typography sx={{ color: is_referred ? '#1e141487' : '#FFF' }} variant="subtitle2">
                        {subHeader1}
                      </Typography>
                    </Grid>
                  </Grid>
                  )}
                  {source &&
                    <Grid item className={classes.subHeaderTitle}>
                      <Typography sx={{ color: is_referred ? '#1e141487' : '#FFF' }} variant="subtitle2">
                        {source}
                      </Typography>
                    </Grid>
                  }
                  <Grid container direction="row" justifyContent="space-between" alignItems="baseline" flexWrap={"nowrap"}>
                    <Grid item xs={11} className={classes.subHeaderTitle}>
                      <Typography sx={{ color: is_referred ? '#1e141487' : '#FFF' }} variant="subtitle2">
                        {bottomText}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography align="right">
                        {icons.map((e, i, arr) =>
                          <React.Fragment key={`cl-${e.iconType}-${i}`}>
                            {checkCondition(e.iconType !== 'more',
                              <>{getActions(id, e.iconType, needs_confirmation ?? needs_update, e.callback, is_recommended, classes)}</>
                            )}
                            {checkCondition(e.iconType === 'more',
                              <>
                                <ClickAwayListener onClickAway={() => handleClose()}>
                                  <HtmlTooltip
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                    onClose={() => handleClose()}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    placement="bottom-end"
                                    title={<></>}
                                    // interactive={true}
                                    arrow>
                                    <IconButton aria-controls="simple-menu" aria-haspopup="true" size="small"
                                      style={{ paddingLeft: '0px' }}
                                      onClick={(event) => handleMoreClick(event)}>
                                      <MoreVertOutlinedIcon style={checkCondition(is_recommended, { color: '#FFFFFF', fontSize: "19px" }, { fontSize: "19px" })} />
                                    </IconButton>
                                  </HtmlTooltip>
                                </ClickAwayListener>
                                <Menu
                                  id="simple-menu"
                                  MenuListProps={{ disablePadding: true }}
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={() => handleClose()}
                                  anchorOrigin={{ vertical: "top", horizontal: "left", }}
                                  transformOrigin={{ vertical: "top", horizontal: "left", }}
                                >
                                  <MenuItem sx={prgStatusColor} onClick={() => !prgStatus && onDeleteMedicationClick()}>
                                    <DeleteOutlinedIcon sx={prgStatusColor} fontSize="small" className={classes.notActiveIcon} />
                                    <Typography sx={prgStatusColor} variant="body2" className={classes.desc}>Delete</Typography>
                                  </MenuItem>
                                  <MenuItem onClick={() => onHelpClick()}>
                                    <HelpOutlineOutlinedIcon fontSize="small" className={classes.notActiveIcon} />
                                    <Typography variant="body2" className={classes.desc}>Help</Typography>
                                  </MenuItem>
                                  <MenuItem onClick={() => onMedDetailsClick()}>
                                    <InfoOutlinedIcon fontSize="small" className={classes.notActiveIcon} />
                                    <Typography variant="body2" className={classes.desc}>Med details</Typography>
                                  </MenuItem>
                                  <MenuItem onClick={() => onMedEvidenceClick()}>
                                    <NoteAddOutlinedIcon fontSize="small" className={classes.notActiveIcon} />
                                    <Typography variant="body2" className={classes.desc}>Med evidence</Typography>
                                  </MenuItem>
                                  <MenuItem sx={prgStatusColor} onClick={() => !prgStatus && onRefClick()}>
                                    <NotificationsActiveOutlinedIcon sx={prgStatusColor} fontSize="small" className={classes.notActiveIcon} />
                                    <Typography sx={prgStatusColor} variant="body2" className={classes.desc}>Referral</Typography>
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </React.Fragment>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          }
        </>
      }
    </Grid >
    {openDialog &&
      <ContextualHelpComponent
        open={openDialog}
        handleClose={closeDialog}
        id={getMedIntolId()}
        title={leftHeader?.name || leftHeader}
        programs={programs}
        category={category}
      />
    }
    {openRationale && (
      <RationaleDialogComponent
        open={openRationale}
        title={leftHeader?.name || leftHeader}
        category={entityRef.replaceAll('_', ' ')}
        input={{
          program: sortProgramNyName(),
          name: name || ''
        }}
        handleClose={handleRationaleClose}
      />
    )}
    {openMedEvidenceDialog &&
      <MedEvidenceComponent
        open={openMedEvidenceDialog}
        handleClose={closeMedEvidenceDialog}
        title={leftHeader?.name || leftHeader}
        input={{
          patientId: patientId,
          program: programs,
          med_id: id
        }}

      />
    }
  </>)
}

export default withStyles(stylesheet)(CardLayoutComponent);