import { environment } from '../../environments/environment'
import atman_logo from '../../assets/atmanlogo.svg'

const oktaAuthConfig = {
  issuer: environment.okta.issuer,
  clientId: environment.okta.clientId,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  tokenManager: {
    autoRenew: true,
    //  expireEarlySeconds: 60
  },
  //pkce: true,
}

const oktaAuthRenwewConfig = {
  issuer: environment.okta.issuer,
  clientId: environment.okta.clientId,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
}

const oktaSignInConfig = {
  baseUrl: environment.okta.baseUrl,
  el: '#widget-container',
  useInteractionCodeFlow: false,
  useClassicEngine: true,
  clientId: environment.okta.clientId,
  redirectUri: window.location.origin + '/login/callback',
  logo: atman_logo,
  logoText: 'Atman Health',
  helpSupportNumber: '',
  brandName: '',
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  flow: 'login',
  services: {
    autoRenew: true,
    autoRemove: true,
    syncStorage: true,
    renewOnTabActivation: true,
    tabInactivityDuration: 1800, // seconds
  },
  tokenManager: {
    autoRenew: true,
    // expireEarlySeconds: 60
  },
  // customButtons: [
  //   {
  //     i18nKey: 'customADButton.title',
  //     className: 'btn-customAuth',
  //     click: () => {
  //       window.location.href = `https://outlook.office.com/?client_id=${environment.okta.clientId}&redirect_uri=https://login.microsoftonline.com/f509e65c-8e0b-472d-b270-6ed49b8329dd/saml2`
  //     },
  //   },
  // ],
  // i18n: {
  //   en: {
  //     'primaryauth.title': 'Atman Health Sign in ',
  //     'primaryauth.username.placeholder': 'Username',
  //     'primaryauth.password.placeholder': 'Password',
  //     // Errors
  //     'error.username.required': 'Please enter an username',
  //     'error.password.required': 'Please enter a password',
  //     'errors.E0000004': 'Sign in failed!',
  //     'customButton.title': 'Atman Sign in ',
  //     'customADButton.title': 'Microsoft AD sign-in',
  //   },
  // },
  authParams: {
    // If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to uncomment the below line
    // pkce: false
  },
  features: {
    idpDiscovery: true,
  },
  idpDiscovery: {
    // requestContext: 'https://atmanhealth.okta.com/home/oidc_client/0oa72zlq3nFn8ZYiG5d7/aln177a159h7Zf52X0g8'
    requestContext: window.location.href,
  },
  // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
}

export { oktaAuthConfig, oktaAuthRenwewConfig, oktaSignInConfig }
