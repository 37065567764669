import { ToastPosition } from "react-toastify";
import moment from 'moment'
class Helper {
	static toCamelCase = (name: string = '') => {
		return name !== '' && name?.replace(
			/\w\S*/g,
			function (txt) {
				return txt.charAt(0).toUpperCase() + txt.slice(1);
			}
		);
	}

	static isPrgInActive = (program_enrollments: any, prg: any) => {
		if (prg?.length < 1)
			return false
		const inactivePrg = program_enrollments
			?.map((i: any) => { if (i.status === 'inactive') return i.program })
			?.filter((i: any) => i !== undefined)
		return (prg?.length === 1) ? inactivePrg?.includes(prg[0]) : prg?.every((p: any) => inactivePrg.includes(p))
	}

	static toCamelCaseObject = (data: any) => {
		if (data !== '' && data !== undefined) {
			const nameObj = data?.split(',')
			return nameObj?.map((i: any) => Helper.toCamelCase(i))
		}
	}

	static toCamelCaseRole = (currentRole: any) => {
		if (currentRole !== '' && currentRole !== undefined) {
			const userRole = currentRole?.split(',')?.find((i: any) => ['PROVIDER', 'NAVIGATOR'].includes(i))
			return (userRole !== '' && userRole !== undefined) ? Helper.toCamelCase(userRole) : ''
		}
	}

	static toLowerlCase = (name: string = '') => {
		return name.replace(
			/\w\S*/g,
			function (txt) {
				return txt.charAt(0).toLowerCase() + txt.substr(1);
			}
		);
	}

	static stageName = (name: string = '') => {
		return name.replace(
			/\w\S*/g,
			function (txt) {
				const newTxt = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				return newTxt.replaceAll('_', ' ')
			}
		);
	}
	static getError = (error: any) => {
		return error?.payload?.response?.data?.error?.message || error?.payload?.message || 'Unknown Error';
	}

	static getErrorText = (payload: any) => {
		return payload?.response?.data?.error?.message || payload?.message || 'Unknown Error';
	}

	static bottom_center = () => {
		return {
			position: 'bottom-center' as ToastPosition,
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
		}
	}

	static dateChecking = (input: string = '') => {
		const temp = input.split(' on ')
		if (temp.length > 1 && moment(temp[1], 'DD MMM YYYY hh:mm A').isValid()) {
			return `${temp[0]} on ${moment(temp[1], 'DD MMM YYYY hh:mm A').utc(true).local().format('DD MMM, YYYY hh:mm A')}`
		}
		return input
	}

	static throttle = (fn: any, delay: number | undefined) => {
		let run = false
		return function (...args: any) {
			if (!run) {
				fn(...args)
				run = true
				setTimeout(() => run = false, delay)
			}
		}
	}

	static checkGroup = (group: any, currentRole: any) => {
		if (group !== '' && group !== undefined && typeof group === 'string')
			group = group?.split(',')
		if (currentRole !== '' && currentRole !== undefined && typeof currentRole === 'string')
			currentRole = currentRole?.split(',')
		return group?.some((i: any) => currentRole?.indexOf(i) >= 0)
	}

	static getRXnorm = (name: any, rxnorm: any) => (name && rxnorm && rxnorm !== 'none' && rxnorm !== undefined) ? `${name} [${rxnorm}]` : name

	static handleReportFile = (data: any, name: string) => {
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(new Blob([data]))
		link.setAttribute('download', `${name}_${moment().unix()}.csv`);
		document.body.appendChild(link);
		link.click()
	}
}

export default Helper